<template>
  <div>
    <div
      v-if="submenuDropdownOpen"
      class="w-screen h-screen fixed top-0 left-0 z-10"
      @click="submenuDropdownOpen = false"
    />

    <div class="block xs:hidden">
      <SubMenu
        :sub-menu-items="subMenuItems"
        :default-view="view"
        @click="showView"
      />
    </div>

    <img
      id="header-image"
      :src="currentTheme.coverImage2"
      class="header-image hidden xs:block"
    >

    <div class="m-4 xs:m-6 mb-4 pb-4">
      <MemberCard
        class="hidden xs:block"
        @showView="showView"
      />

      <div
        class="relative z-10 text-gray-800 ml-0 max-w-5xl ml-auto mr-auto hidden xs:block"
        style="margin-top: 145px;"
      >
        <SubMenu
          :sub-menu-items="subMenuItems"
          :default-view="view"
          @click="showView"
        />
      </div>


      <!-- Dashboard -->
      <div
        v-show="view === 'Dashboard'"
        class="max-w-5xl ml-auto mr-auto"
      >
        <div class="flex items-center -m-4 mb-0 xs:m-0 xs:mt-6 xs:w-full">
          <img
            class="inline-block w-8/12 h-56 xs:h-64 object-cover object-center"
            :src="currentTheme.home.welcome"
          >

          <div
            class="inline-block w-9/12 -ml-28 xs:-ml-40 py-3 px-4 xs:py-4 xs:px-5 sm:py-5 sm:px-6 sm:pr-16"
            style="background-color: rgb(243, 243, 243);"
          >
            <div class="brand-heading tracking-wider text-gray-800 xs:text-2xl">
              Welcome {{ forename }}
            </div>
            <div class="text-sm xs:text-lg brand-body text-gray-700 mt-1">
              {{ club.dashboardMessage }}
            </div>
            <div v-if="!isExpired">
              <div
                v-if="packageName !== undefined && packageName !== null"
                class="inline-block text-sm xs:text-base mr-2 xs:hidden"
              >
                <div class="text-gray-600 uppercase tracking-widest">
                  <div class="inline text-brand-800">
                    &bull;
                  </div>
                  {{ packageName }}
                </div>
              </div>
            </div>

            <div v-else>
              <div
                class="inline-block text-sm xs:text-base mr-2 xs:hidden"
              >
                <div
                  class="text-red-700 uppercase tracking-widest"
                  @click="showView('Renew')"
                >
                  <div class="inline text-brand-800">
                    &bull;
                  </div>
                  Membership expired
                </div>
              </div>
            </div>
          </div>
        </div>

        <Bookings :bookings="bookings" />

        <div class="mt-5">
          <div v-if="!isExpired">
            <router-link
              :to="'/booking/clubs/' + homeClubId"
              class="noSelect"
            >
              <div
                class="flex justify-center items-center h-32 text-center w-full bg-center bg-cover cursor-pointer select-none"
                :class="getBackground('teeTimes', currentTheme.home.teetimes)"
              >
                <div class="text-white text-2xl tracking-wider brand-heading">
                  {{ homeClubName }} Tee Times
                </div>
              </div>
            </router-link>
          </div>

          <div
            v-else
            @click="showView('Renew')"
          >
            <div
              class="flex justify-center items-center h-32 text-center w-full bg-center bg-cover cursor-pointer select-none"
              :class="getBackground('teeTimes', currentTheme.home.teetimes)"
            >
              <div class="text-white text-2xl tracking-wider brand-heading">
                Renew Your Membership
              </div>
            </div>
          </div>


          <div class="flex mt-5">
            <div class="mr-2 w-1/2">
              <router-link
                to="/account/profile/BookingHistory"
                class="noSelect"
              >
                <div
                  class="flex justify-center items-center h-32 text-center bg-center bg-cover cursor-pointer"
                  :class="getBackground('bookingHistory', currentTheme.home.history)"
                >
                  <div class="sm:flex">
                    <div class="text-white text-2xl tracking-wider brand-heading">
                      Booking
                    </div>
                    <div class="text-white text-2xl tracking-wider brand-heading sm:ml-2">
                      History
                    </div>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="ml-2 w-1/2">
              <div
                class="flex justify-center items-center h-32 text-center bg-center bg-cover cursor-pointer noSelect"
                :class="getBackground('additionalPoints', currentTheme.home.topup)"
                @click="additionalPoints();"
              >
                <div class="sm:flex">
                  <div class="text-white text-2xl tracking-wider brand-heading">
                    <span v-if="!isExpired">Additional</span><span v-else>Tee</span>
                  </div>
                  <div class="text-white text-2xl tracking-wider brand-heading sm:ml-2">
                    <span v-if="!isExpired">Points</span><span v-else>Times</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Statistics -->
      <div
        v-show="view === 'Statistics'"
        class="text-gray-800 max-w-5xl ml-auto mr-auto mt-2"
      >
        <div
          v-if="loadingStates.stats"
          class="flex justify-center items-center my-10"
        >
          <loadingSpinner />
        </div>

        <div
          v-else
          class="inline-block xs:mt-2 -ml-2 -mr-2"
        >
          <div v-if="statistics !== undefined">
            <StatsBlock
              name="total bookings"
              :value="statistics.TotalGames"
              icon="flag"
            />
            <!-- <StatsBlock
              name="clubs visited"
              :value="statistics.ClubsVisited"
              icon="fa-road"
            /> -->
            <!-- <StatsBlock
              name="bookings"
              :value="statistics.HomeGames"
              icon="fa-home"
            /> -->
            <!-- <StatsBlock
              name="away bookings"
              :value="statistics.AwayGames"
              icon="fa-map-marked-alt"
            /> -->
            <StatsBlock
              name="summer bookings"
              :value="statistics.SummerGames"
              icon="sun"
            />
            <StatsBlock
              name="winter bookings"
              :value="statistics.WinterGames"
              icon="winter"
            />
            <StatsBlock
              name="buddy bookings"
              :value="statistics.BuddyBookings"
              icon="buddyBookings"
            />
            <!-- <StatsBlock
              name="furthest booking"
              :value="Math.round(statistics.FurthestDistanceTravelledMetres * 0.00062137) + 'mi'"
              icon="fa-globe-europe"
            /> -->
            <StatsBlock
              name="app bookings"
              :value="statistics.AppBookings"
              icon="appBookings"
            />
            <StatsBlock
              name="guest count"
              :value="statistics.GuestCount"
              icon="guests"
            />
            <StatsBlock
              name="renewal count"
              :value="statistics.RenewalCount"
              icon="renew"
            />
            <StatsBlock
              name="referral count"
              :value="statistics.ReferralCount"
              icon="refer"
            />
          </div>
        </div>

        <div
          v-if="statistics === undefined && !loadingStates.stats"
          class="text-center ml-auto mr-auto text-2xl p-4 tracking-wide brand-heading"
        >
          <div class="pt-8 pb-8">
            <div>Sorry, you do not have any statistics yet</div>
          </div>
        </div>
      </div>

      <!-- Renew -->
      <div v-show="view === 'Renew'">
        <Renew
          :available-packages="availablePackages"
        />
      </div>

      <!-- Top-up -->
      <div v-show="view === 'Top-up'">
        <TopUp
          :available-packages="availablePackages"
        />
      </div>

      <!-- Referrals -->
      <!-- <div v-show="view === 'Referrals'">
        <Referrals />
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import services from '@/app/shared/services';
import LoadingSpinner from '../../shared/components/base/loadingSpinner.vue';
import SubMenu from '../../shared/components/base/menu/subMenu.vue';
import StatsBlock from '../../shared/components/member/statistics/statsBlock.vue';
import TopUp from '../../shared/components/member/topUp/topUp.vue';
import Renew from '../../shared/components/member/renew/renew.vue';
import Bookings from '../../shared/components/member/bookings/bookings.vue';
import MemberCard from '../../shared/components/member/memberCard.vue';

export default {
  name: 'App',
  components: {
    LoadingSpinner,
    SubMenu,
    StatsBlock,
    TopUp,
    Renew,
    Bookings,
    MemberCard,
  },

  data() {
    return {
      basket: '0',
      view: 'Dashboard',
      submenuDropdownOpen: false,
      ex1: { label: 'color', val: 25, color: 'orange darken-3' },
      ex2: { label: 'track-color', val: 75, color: 'green lighten-1' },
      ex3: { label: 'thumb-color', val: 50, color: 'brand' },
      statistics: undefined,
      bookings: [],
      loadingStates: {
        stats: true,
        achievementScore: true,
        bookings: true,
      },

      backgroundCSSLoaded: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      availablePackages: undefined,
    };
  },

  computed: {
    ...mapState({
      flexiPoints: state => state.user.flexiPoints,
      homeClubName: state => state.user.homeClubName,
      homeClubId: state => state.user.homeClubId,
      forename: state => state.user.forename,
      packageName: state => state.user.packageName,
      isExpired: state => state.user.IsExpired,
    }),

    subMenuItems() {
      const items = ['Dashboard', 'Statistics', 'Renew'];
      if (!this.isExpired) {
        items.push('Top-up');
      }

      return items;
    },

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
    }),
  },

  created() {
    this.assignDefaultView();
  },

  mounted() {
    document.title = `${this.club.clubName} Members Area`;
    const favicon = document.getElementById('favicon');
    if (favicon !== null) {
      favicon.href = `/img/icons/${this.club.clubId}.ico`;
    }

    this.getStatistics();
    this.getUpcomingBookings();
    this.getPackages();
  },

  methods: {
    getPackages() {
      services.checkout.availablePackages().then((data) => {
        this.availablePackages = data;
      });
    },

    additionalPoints() {
      if (!this.isExpired) {
        this.view = 'Top-up';
        window.scrollTo(0, 0);
      } else {
        this.$router.push(`/booking/clubs/${this.homeClubId}`);
      }
    },

    assignDefaultView() {
      if (this.$route !== undefined && this.$route.params.view !== undefined) {
        const viewParam = this.$route.params.view;
        if (viewParam === 'Bookings') {
          this.view = 'Bookings';
        } else if (viewParam === 'Renew') {
          this.view = 'Renew';
        } else if (viewParam === 'Topup') {
          this.view = 'Top-up';
        }
        // else if(viewParam === "Referrals"){
        //   this.view = "Referrals";
        // }
      }
    },

    getBackground(className, image) {
      if (!this.backgroundCSSLoaded.includes(className)) {
        this.backgroundCSSLoaded.push(className);
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `.${className} { background: linear-gradient(rgba(19, 33, 48, 0.4), rgba(19, 33, 48, 0.4)), url(${image}); background-position: center; background-size: cover; background-size: 100%; transition: all 1s ease; -moz-transition: all 1s ease; -ms-transition: all 1s ease; -webkit-transition: all 1s ease; -o-transition: all 1s ease; } .${className}:hover { background-size: 110%; }`;
        document.getElementsByTagName('head')[0].appendChild(style);
      }

      return className;
    },

    showView(view) {
      this.view = view;

      // view-specific loading - todo: make this better
      if (view === 'Bookings') {
        this.getUpcomingBookings();
      }
    },

    getStatistics() {
      services.statistics.getUserStatistics().then((data) => {
        this.statistics = data;
        this.loadingStates.stats = false;
      });
    },

    getUpcomingBookings() {
      if (this.loadingStates.bookings) {
        services.booking.upcomingBookings().then((result) => {
          this.bookings = result;

          this.bookings.reverse();

          this.loadingStates.booking = false;
        });
      }
    },
  },
};
</script>
