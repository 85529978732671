<template>
  <div
    class="relative z-10 xs:-mt-32 max-w-5xl xs:-mb-32 ml-auto mr-auto shadow-lg"
    style="background-image: linear-gradient(rgba(255, 255, 255, 0.84),rgba(255,255,255,0.98), rgba(255,255,255,1))"
  >
    <div class="xxs:flex w-full min-w-full">
      <div
        class="hidden xxs:flex items-center justify-center h-24 xxs:h-32 w-full xxs:w-auto xs:h-36 sm:h-40 align-top bg-brand-500 object-cover select-none"
      >
        <div
          v-if="forename !== undefined"
          class="ml-12 mr-12 xs:ml-24 xs:mr-24 mb-1 text-white text-6xl font-thin"
        >
          {{ forename.charAt(0) }}{{ surname.charAt(0) }}
        </div>
      </div>

      <div class="xxs:inline-block p-2 pl-6 pr-4 flex-grow border border-l-0">
        <div class="flex justify-between items-center">
          <div
            v-if="forename !== undefined"
            class="inline-block brand-heading text-xl text-gray-700 xs:text-2xl sm:text-3xl md:text-3xl tracking-wide"
          >
            {{ forename }} {{ surname }}
          </div>

          <div
            v-if="packageName !== undefined && packageName !== null"
            class="inline-block text-sm xs:text-base mr-2"
          >
            <div class="text-gray-600 uppercase tracking-widest">
              <div class="inline text-brand-800">
                &bull;
              </div>
              {{ packageName }}
            </div>
          </div>
        </div>

        <div
          v-if="homeClubName !== undefined"
          class="mt-4 pb-4 -ml-1 text-gray-600 text-lg xs:text-xl md:text-2xl brand-heading tracking-wider"
        >
          <!-- {{ homeClubName }} -->
          <img
            class="h-6"
            :src="currentTheme.loginLogoAlternative"
          >
        </div>

        <div
          v-if="!isExpired"
          class="text-green-700 text-xs xs:text-sm sm:text-base brand-body"
        >
          Renewal due on {{ membershipExpires | moment("dddd, Do MMMM YYYY") }} at {{ membershipExpires | moment("h:mma") }}
        </div>

        <div
          v-else
          class="text-red-700 text-xs xs:text-sm sm:text-base brand-body"
        >
          Your membership has expired, please <div
            class="inline-block select-none cursor-pointer hover:text-red-500 transition-all duration-500"
            @click="renew()"
          >
            renew
          </div> to continue your membership.
        </div>
        <!--
        <div class="xs:mt-2 text-xs xs:text-sm sm:text-base">
          <i
            v-for="(index) in renewalStars"
            :key="index"
            class="fas fa-star text-brand-800"
          />
          <i
            v-for="(index) in starsRemaining"
            :key="index + 100"
            class="far fa-star"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {

  data() {
    return {
      renewalStars: 1,
    };
  },

  computed: {

    ...mapState({
      forename: state => state.user.forename,
      surname: state => state.user.surname,
      homeClubName: state => state.user.homeClubName,
      packageName: state => state.user.packageName,
      membershipExpires: state => state.user.membershipExpires,
      isExpired: state => state.user.IsExpired,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
    }),

    starsRemaining() {
      if (this.renewalStars <= 5) {
        return 5 - this.renewalStars;
      }
      return 0;
    },
  },

  watch: {

  },

  methods: {
    renew() {
      this.$emit('showView', 'Renew');
    },
  },
};


</script>
