<template>
  <div>
    <div
      id="notifications"
      class="z-50 leading-normal"
    >
      <div class="bg-white border border-b-0 shadow-lg">
        <div
          class="border-b p-2 pl-3 pr-3 tracking-wide"
          style="background: linear-gradient(to bottom, rgba(255,255,255,1), rgba(240,240,240,1));"
        >
          Notifications
        </div>

        <div
          v-if="initialLoad"
          class="w-full min-w-full p-4 text-center"
        >
          <LoadingSpinner class="ml-auto mr-auto" />
        </div>

        <div
          v-else
          id="notificationList"
          class="overflow-y-scroll"
          style="max-height: 340px;"
        >
          <NotificationItem
            v-for="notification in filteredList"
            :key="notification.id"
            :notification="notification"
            @click="closeNotificationMenu()"
          />
          <div
            v-if="filteredList.length === 0"
            class="text-center p-3"
          >
            No notifications
          </div>
          <div
            v-if="!endOfList && filteredList.length > 0"
            class="w-full min-w-full pb-1 text-center bg-gray-100"
          >
            <LoadingSpinner class="ml-auto mr-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationItem from './notificationItem.vue';
import LoadingSpinner from '../base/loadingSpinner.vue';
import services from '@/app/shared/services';

export default {

  components: {
    LoadingSpinner,
    NotificationItem,
  },

  data() {
    return {
      initialLoad: true,
      loading: false,
      index: 0,
      endOfList: false,
      notifications: undefined,
    };
  },

  computed: {
    filteredList() {
      return this.notifications.filter(n => n.type !== 1);
    },
  },

  mounted() {
    this.getNotifications();
  },

  methods: {
    getNotifications() {
      services.notification.notifications(this.index).then((data) => {
        if (this.notifications === undefined) {
          this.notifications = data;
        } else {
          data.forEach(not => this.notifications.push(not));
        }

        if (data.length < 15) {
          this.endOfList = true;
        }

        if (this.initialLoad) {
          this.initialLoad = false;
          setTimeout(() => {
            this.addScrollListener();
          }, 500);
        } else {
          this.loading = false;
        }
      });
    },

    addScrollListener() {
      const menu = document.getElementById('notificationList');
      menu.addEventListener('scroll', () => {
        if (!this.loading && !this.endOfList) {
          if ((menu.scrollHeight - menu.scrollTop) - 5 < menu.clientHeight) {
            this.loading = true;
            this.index += 1;
            this.getNotifications();
          }
        }
      });
    },

    closeNotificationMenu() {
      this.$emit('click', false);
    },
  },

};

</script>

<style>
  /* width */
  #notifications ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  #notifications ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  #notifications ::-webkit-scrollbar-thumb {
    background-color: #999;
  }

  /* Handle on hover */
  #notifications ::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  @media (min-width: 768px){
    #notifications {
    position: absolute;
    top: 47px;
    right: -60px;
    width: 380px;
  }

  #notifications:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    color: transparent;
    border: 10px solid rgb(0, 0, 0);
    border-color: transparent transparent #e2e8f0;
    margin-left: 302px;
    margin-top: -5px;
  }

}

@media (max-width: 768px){
  #notifications {
    position: fixed;
    top: 72px;
    left: 0px;
    width: 100vw;
  }

}
</style>
