<template>
  <div
    class="md:h-12 relative z-0 bg-white xs:shadow-md xs:border select-none noSelect"
  >
    <div
      id="subMenu"
      class="navbar flex justify-between cursor-pointer brand-heading text-gray-800 tracking-wider text-center md:float-left
      text-base overflow-y-scroll select-none noSelect"
      style="max-width: 100vw; scroll-behavior: smooth;"
    >
      <div
        v-for="(item, index) in subMenuItems"
        :id="item"
        :key="index"
        :class="(view === item)?'border-inside-bottom-brand-800':'hover:border-inside-bottom-secondary-300 transition-400 transition-ease'"
        class="item p-3 pl-6 pr-6 xs:border-r w-full md:w-auto select-none transition-all whitespace-no-wrap flex items-center justify-center"
        @click="showView(item)"
      >
        {{ item }}
        <div
          v-if="itemNotifications !== undefined && itemNotifications[index] !== null && itemNotifications[index] > 0"
          class="bg-red-700 leading-none text-white p-0 rounded-full text-center default-font ml-1 text-sm flex items-center justify-center"
          style="padding-left: 4px; padding-right: 4px; margin-top: 4px; margin-bottom: 4px;"
        >
          {{ itemNotifications[index] }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  props: {
    subMenuItems: {
      type: Array,
      default: null,
    },
    itemNotifications: {
      type: Array,
      default: undefined,
    },
    defaultView: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      view: '',
      submenuDropdownOpen: false,
    };
  },

  computed: {
    filteredItems() {
      return this.subMenuItems.filter(i => i !== this.view);
    },

    currentView() {
      return this.defaultView;
    },
  },

  watch: {
    currentView() {
      this.scrollToView(this.currentView);
      this.view = this.currentView;
    },
  },

  mounted() {
    if (this.defaultView === null) {
      this.view = this.subMenuItems[0];
    } else {
      this.scrollToView(this.defaultView);
      this.view = this.defaultView;
    }
  },

  methods: {
    scrollToView(target) {
      // Check if selected view is currently being displayed, if not scroll to it
      const doc = document.getElementById('subMenu');
      const left = (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
      const rect = document.getElementById(target).getBoundingClientRect();

      if ((left + screen.width) < rect.right) {
        doc.scrollLeft = rect.right;
      }
    },

    showView(view) {
      this.view = view;
      this.$emit('click', view);
    },
  },
};
</script>

<style>
  /* Hide scrollbar for Chrome, Safari and Opera */
.navbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

</style>
