<template>
  <div class="inline-block w-full xxs:w-1/2 sm:w-1/3 md:w-1/4">
    <div
      class="h-32 m-2 border bg-white shadow-md text-center hover:shadow-lg transition-box-shadow transition-ease"
    >
      <div class="p-6 pt-5">
        <div
          class="flex justify-center items-center"
          style="font-size: 1.8rem;"
        >
          <div class="text-2xl pr-3 text-brand-500">
            <svg
              v-if="icon === 'flag'"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="flag"
              class="w-6 h-6"
              role="img"
              viewBox="0 0 512 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M512 91.33v277c0 11.31-7.1 21.88-18.5 26.47C317.7 465 281.7 331.25 96 416V102a56.57 56.57 0 0 0 14.64-15c194.19-74.48 184.75 58.25 352-20.08C485.2 56.31 512 68.26 512 91.33z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M120 56a55.93 55.93 0 0 1-24 46v388a22 22 0 0 1-22 22H54a22 22 0 0 1-22-22V102a56 56 0 1 1 88-46z"
            /></g></svg>
            <svg
              v-if="icon === 'sun'"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="sun"
              class="w-6 h-6"
              role="img"
              viewBox="0 0 512 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M502.42 240.5l-94.7-47.3 33.5-100.4c4.5-13.6-8.4-26.5-21.9-21.9l-100.4 33.5-47.41-94.8a17.31 17.31 0 0 0-31 0l-47.3 94.7L92.7 70.8c-13.6-4.5-26.5 8.4-21.9 21.9l33.5 100.4-94.7 47.4a17.31 17.31 0 0 0 0 31l94.7 47.3-33.5 100.5c-4.5 13.6 8.4 26.5 21.9 21.9l100.41-33.5 47.3 94.7a17.31 17.31 0 0 0 31 0l47.31-94.7 100.4 33.5c13.6 4.5 26.5-8.4 21.9-21.9l-33.5-100.4 94.7-47.3a17.33 17.33 0 0 0 .2-31.1zm-155.9 106c-49.91 49.9-131.11 49.9-181 0a128.13 128.13 0 0 1 0-181c49.9-49.9 131.1-49.9 181 0a128.13 128.13 0 0 1 0 181z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M352 256a96 96 0 1 1-96-96 96.15 96.15 0 0 1 96 96z"
            /></g></svg>
            <svg
              v-if="icon === 'winter'"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="cloud-showers-heavy"
              class="w-6 h-6"
              role="img"
              viewBox="0 0 512 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M183.9 370.1a15.88 15.88 0 0 0-21.8 6l-64 112a15.92 15.92 0 0 0 6 21.8 16 16 0 0 0 21.8-6l64-112a16 16 0 0 0-6-21.8zm96 0a15.88 15.88 0 0 0-21.8 6l-64 112a15.92 15.92 0 0 0 6 21.8 16 16 0 0 0 21.8-6l64-112a16 16 0 0 0-6-21.8zm-192 0a15.88 15.88 0 0 0-21.8 6l-64 112a15.92 15.92 0 0 0 6 21.8 16 16 0 0 0 21.8-6l64-112a16 16 0 0 0-5.99-21.8zm384 0a15.88 15.88 0 0 0-21.8 6l-64 112a15.92 15.92 0 0 0 6 21.8 16 16 0 0 0 21.8-6l64-112a16 16 0 0 0-6.01-21.8zm-96 0a15.88 15.88 0 0 0-21.8 6l-64 112a15.92 15.92 0 0 0 6 21.8 16 16 0 0 0 21.8-6l64-112a16 16 0 0 0-6.01-21.8z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M512 224a96 96 0 0 1-96 96H96a96 96 0 0 1-96-96c0-42.5 27.8-78.2 66.1-90.8A113.72 113.72 0 0 1 64 112 112 112 0 0 1 176 0c43.3 0 80.4 24.8 99 60.8C289.7 43.3 311.4 32 336 32a80 80 0 0 1 80 80 78.09 78.09 0 0 1-1.6 16.2c.5 0 1-.2 1.6-.2a96 96 0 0 1 96 96z"
            /></g></svg>
            <svg
              v-if="icon === 'buddyBookings'"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="users"
              class="w-6 h-6"
              role="img"
              viewBox="0 0 640 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M96 224a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64zm480 32h-64a63.81 63.81 0 0 0-45.1 18.6A146.27 146.27 0 0 1 542 384h66a32 32 0 0 0 32-32v-32a64.06 64.06 0 0 0-64-64zm-512 0a64.06 64.06 0 0 0-64 64v32a32 32 0 0 0 32 32h65.9a146.64 146.64 0 0 1 75.2-109.4A63.81 63.81 0 0 0 128 256zm480-32a64 64 0 1 0-64-64 64.06 64.06 0 0 0 64 64z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M396.8 288h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 128 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 396.8 288zM320 256a112 112 0 1 0-112-112 111.94 111.94 0 0 0 112 112z"
            /></g></svg>
            <svg
              v-if="icon === 'appBookings'"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="mobile"
              class="w-6 h-6"
              role="img"
              viewBox="0 0 320 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M0 384v80a48 48 0 0 0 48 48h224a48 48 0 0 0 48-48v-80zm160 96a32 32 0 1 1 32-32 32 32 0 0 1-32 32z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M0 384V48A48 48 0 0 1 48 0h224a48 48 0 0 1 48 48v336z"
            /></g></svg>
            <svg
              v-if="icon === 'guests'"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="user-plus"
              class="w-6 h-6"
              role="img"
              viewBox="0 0 640 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M640 224v32a16 16 0 0 1-16 16h-64v64a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-64h-64a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h64v-64a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v64h64a16 16 0 0 1 16 16z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm89.6 32h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6A134.43 134.43 0 0 0 313.6 288z"
            /></g></svg>
            <svg
              v-if="icon === 'renew'"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="sync"
              class="w-6 h-6"
              role="img"
              viewBox="0 0 512 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M0 500V299.67a12 12 0 0 1 12-12h200.33a12 12 0 0 1 12 12v47.41a12 12 0 0 1-12.57 12l-101.87-4.88a176.07 176.07 0 0 0 317.25-56.94 12 12 0 0 1 11.67-9.26h49.09a12 12 0 0 1 11.8 14.18C478.07 417.08 377.19 504 256 504a247.43 247.43 0 0 1-188.76-87.17l4.13 82.57a12 12 0 0 1-12 12.6H12a12 12 0 0 1-12-12z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M12.3 209.82C33.93 94.92 134.81 8 256 8a247.4 247.4 0 0 1 188.9 87.34l-4-82.77A12 12 0 0 1 452.92 0h47.41a12 12 0 0 1 12 12v200.33a12 12 0 0 1-12 12H300a12 12 0 0 1-12-12v-47.41a12 12 0 0 1 12.57-12l101.53 4.88a176.07 176.07 0 0 0-317.24 56.94A12 12 0 0 1 73.19 224H24.1a12 12 0 0 1-11.8-14.18z"
            /></g></svg>
            <svg
              v-if="icon === 'refer'"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false"
              data-prefix="fad"
              data-icon="user-friends"
              class="w-6 h-6"
              role="img"
              viewBox="0 0 640 512"
            ><g class="fa-group"><path
              class="fa-secondary"
              fill="currentColor"
              d="M480 256a96 96 0 1 0-96-96 96 96 0 0 0 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592a48 48 0 0 0 48-48 111.94 111.94 0 0 0-112-112z"
              opacity="0.4"
            /><path
              class="fa-primary"
              fill="currentColor"
              d="M192 256A112 112 0 1 0 80 144a111.94 111.94 0 0 0 112 112zm76.8 32h-8.3a157.53 157.53 0 0 1-68.5 16c-24.6 0-47.6-6-68.5-16h-8.3A115.23 115.23 0 0 0 0 403.2V432a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48v-28.8A115.23 115.23 0 0 0 268.8 288z"
            /></g></svg>
          </div>
          <div class="text-3xl">
            {{ value }}
          </div>
        </div>
        <div class="text-xl pt-1 font-thin">
          {{ name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      return: {
        baseUrl: process.env.VUE_APP_BASE_URL,
      },
    };
  },

  computed: {
    ...mapState({
      flexiPoints: state => state.user.flexiPoints,
    }),
  },
};
</script>
