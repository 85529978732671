import { themeList } from './lists/themeList';

const themeStore = {
  state: {
    showLayout: true,
    squeezePage: false,
    hideHomeClub: false,
    overrideStyle: false,
    hideGlobalLoader: false,
    overridingClubId: 0,
  },

  getters: {
    //  uses themeList instead of state because should never need to set a theme manually maybe
    getCurrentTheme: (state, getters, rootState) => {
      if (state.overrideStyle) {
        return themeList.find(theme => theme.clubId === state.overridingClubId) !== undefined
          ? themeList.find(theme => theme.clubId === state.overridingClubId)
          : themeList.find(theme => theme.clubId === 0);
      }
      return themeList.find(theme => theme.clubId === rootState.user.homeClubId) !== undefined
        ? themeList.find(theme => theme.clubId === rootState.user.homeClubId)
        : themeList.find(theme => theme.clubId === 0);
    },
  },

  mutations: {
    setTheme(state, id) {
      state.currentThemeId = id;
    },

    toggleLayout(state, showLayout) {
      state.showLayout = showLayout;
    },
    toggleHideGlobalLoader(state, hideGlobalLoader) {
      state.hideGlobalLoader = hideGlobalLoader;
    },
    toggleSqueezePage(state, squeezePage) {
      state.squeezePage = squeezePage;
    },
    toggleHideHomeClub(state, hideHomeClub) {
      state.hideHomeClub = hideHomeClub;
    },
    setOverrideStyle(state, value) {
      state.overrideStyle = value;
    },
    setOverridingClubId(state, value) {
      if (!(isNaN(value))) {
        state.overrideStyle = true;
        state.overridingClubId = Number(value);

        const fonts = themeList.find(theme => theme.clubId === state.overridingClubId) !== undefined
          ? themeList.find(theme => theme.clubId === state.overridingClubId)
          : themeList.find(theme => theme.clubId === 0);

        if (fonts !== undefined) {
          for (let i = 0; i < fonts.length; i += 1) {
            if (!this.loadedFonts.includes(fonts[i].family)) {
              this.loadedFonts.push(fonts[i].family);

              const style = document.createElement('style');
              style.appendChild(document.createTextNode(`\
              @font-face {\
                  font-family: ${fonts[i].family};\
                  src: url('${fonts[i].src}') format('${fonts[i].format}');\
              }\
              `));

              document.head.appendChild(style);
            }
          }
        }
      } else {
        state.overridingClubId = 0;
      }
    },
  },

  actions: {

  },
};

export { themeStore };
