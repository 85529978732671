import club from './club.vue';

const clubRoutes = [
  {
    path: '/Club',
    component: club,
    children: [
      {
        path: '',
        name: 'landingNoClub',
        component: () => import('./clubLanding/clubLanding.vue'),
        meta: {
          requiresAuth: false,
          noLayout: true,
          overrideStyle: true,
        },
      },
      {
        path: ':clubName/:id',
        name: 'landing',
        component: () => import('./clubLanding/clubLanding.vue'),
        meta: {
          requiresAuth: false,
          noLayout: true,
          overrideStyle: true,
        },
      },
      {
        path: '/PointsMatrix/:clubName/:clubId',
        name: 'Points Matrix',
        component: () => import('./matrix/viewMatrix.vue'),
        meta: {
          requiresAuth: false,
          overrideStyle: true,
        },
      },
      {
        path: '/T&Cs/:clubName/:clubId',
        name: 'Terms and Conditions',
        component: () => import('./termsAndConditions/termsAndConditions.vue'),
        meta: {
          title: 'Terms & Conditions',
          requiresAuth: false,
          noLayout: true,
          overrideStyle: true,
        },
      },
      {
        path: '',
        redirect: { name: 'landing' },
      },
    ],
  },
];

export default clubRoutes;
