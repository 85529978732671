import Vue from 'vue';
import Router from 'vue-router';

import { pageNotFound } from './shared/components/error-pages';
import { homeRoutes } from './home';
import { bookingRoutes } from './booking';
import { helpRoutes } from './help';
import { clubRoutes } from './club';
import { packageRoutes } from './package';
import { accountRoutes } from './account';
import { buddyRoutes } from './buddy';
import { checkoutRoutes } from './checkout';
import { errorRoutes } from './error';
import { nodeEnv } from '@/environment/environment';
import { store } from '@/app/shared/stores/store';

Vue.use(Router);

const appRoutes = [
  {
    path: '*',
    name: 'pageNotFound',
    component: pageNotFound,
    meta: {
      noLayout: false,
      title: '404 - Page not found',
    },
  },
];

const routes = [...accountRoutes, ...appRoutes, ...bookingRoutes, ...clubRoutes, ...packageRoutes, ...checkoutRoutes, ...buddyRoutes, ...helpRoutes, ...errorRoutes, ...homeRoutes];

const router = new Router({
  mode: nodeEnv === 'cordova' ? '' : 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// authentication check
router.beforeEach(async (to, from, next) => {
  if (to.meta.title !== undefined) {
    document.title = to.meta.title;
  }

  if (to.matched.some(record => record.meta.noLayout)) {
    store.commit('toggleLayout', false);
  } else {
    store.commit('toggleLayout', true);
  }

  if (to.matched.some(record => record.meta.squeezePage)) {
    store.commit('toggleSqueezePage', true);
  } else {
    store.commit('toggleSqueezePage', false);
  }

  if (to.matched.some(record => record.meta.hideHomeClub)) {
    store.commit('toggleHideHomeClub', true);
  } else {
    store.commit('toggleHideHomeClub', false);
  }

  if (to.matched.some(record => record.meta.overrideStyle)) {
    store.commit('setOverrideStyle', true);
  } else {
    store.commit('setOverrideStyle', false);
  }

  if (store.state.user.isAdmin && to.matched.some(record => record.meta.requiresAuth)) {
    if (store.state.user.username === 'tee.time@thegrove.co.uk') {
      window.location.href = 'https://api.xviii.golf/';
      window.location = 'https://api.xviii.golf/';
    }
  }

  // todo: yuck, fix
  if (store.state.user.isAuthenticated) {
    store.commit('toggleHideGlobalLoader', false);
    next(); // continue
  } else if (to.matched.some(record => record.meta.requiresAuth)) {
    store.commit('toggleHideGlobalLoader', true);
    store.dispatch('setAuth', false);

    if (router.currentRoute.name === 'login'
      && router.currentRoute.name === 'loginForClub'
      && router.currentRoute.name === 'loginBad') {
      // do nothing
    } else {
      const returnUrl = `returnUrl=${window.location.pathname}${window.location.search}`;
      const loginPath = `/login/${store.state.user.homeClubName}/${store.state.user.homeClubId}`;
      router.push(`${loginPath}?${returnUrl}`);
    }
  } else {
    store.commit('toggleHideGlobalLoader', true);
    next(); // not authed, no authentication needed
  }
});

export default router;
