<template>
  <div
    class="text-gray-800 max-w-5xl ml-auto mr-auto overflow-hidden mt-6"
  >
    <div class="text-gray-600 overflow-hidden">
      <div
        class="pt-8 sm:pt-16 text-gray-400 overflow-hidden bg-center bg-cover"
        :style="'background-image: linear-gradient(rgba(21,26,47,0.1),rgba(21, 26, 47, 0.2)),url(' + currentTheme.renew.header + ');'"
      >
        <div
          class="text-2xl xs:text-3xl md:text-4xl text-white pt-2 pr-10 pl-10 pb-12 sm:pb-20 brand-heading"
        >
          {{ club.renew.title }}
        </div>
      </div>

      <div v-if="availablePackages !== undefined">
        <div
          class="packageBackground xl:flex items-center justify-evenly xxs:pt-8 xxs:pb-8 xl:pt-16 pb-4 xl:pb-16 mt-4 xs:mt-16"
          :style="getRenewalBackground()"
        >
          <div
            v-for="(clubPackage, index) in availablePackages.PackageGroups.Packages"
            :key="index"
            class="xs:px-16 xl:px-0 py-4 xs:py-8"
            :class="(index === 0)?'flex justify-center sm:justify-start':'flex justify-center sm:justify-end'"
          >
            <Package3
              :index="index"
              :club-package="clubPackage"
              :promocode="promocode"
              :home-club-id="homeClubId"
            />
          </div>
        </div>
      </div>

      <div
        v-else
        class="w-full min-w-full mt-16 mb-4"
      >
        <div class="ml-auto mr-auto text-center">
          <LoadingSpinner />
          <div class="mt-2 text-sm brand-body">
            Loading renewal packages
          </div>
        </div>
      </div>

      <div class="flex justify-center min-w-full mb-6 sm:mb-0 sm:mt-16">
        <img
          class="object-cover hidden sm:inline-block"
          :src="currentTheme.renew.body"
          style="height: 460px; width: 677px;"
        >

        <div class="mt-0 xs:mt-12 sm:mt-1">
          <div
            class="descBackground text-center sm:text-left sm:p-10 sm:m-24 sm:mt-12 ml-0 sm:-ml-24 pr-0 sm:mr-0 brand-heading"
            :style="getBackground()"
          >
            <div
              class="text-xl sm:text-2xl text-white sm:text-gray-800 p-10 sm:p-0 leading-relaxed"
            >
              <span class="text-white sm:text-brand-800 text-2xl sm:text-3xl leading-relaxed">{{ club.renew.body.charAt(0) }}</span>{{ club.renew.body.substring(1) }}
            </div>
          </div>
        </div>
      </div>

      <div
        class="clubBackground flex justify-start items-start mt-10 sm:mt-6"
        :style="getClubBackground()"
      >
        <div
          class="bg-white ml-0 p-4 xs:m-10 text-lg text-center xs:max-w-lg shadow"
        >
          <div class="border border-gray-200 p-4 sm:p-8">
            <div class="text-center text-3xl xxs:text-4xl tracking-wide brand-heading text-brand-500 leading-none">
              {{ club.clubName }}
            </div>
            <div
              class="mt-6 pb-0 text-gray-800 pl-0 brand-body text-brand sm:text-xl"
            >
              {{ club.introduction }}
            </div>

            <div
              class="mt-6 xs:w-64 text-center ml-auto mr-auto"
            >
              <router-link :to="'/Club/' + homeClubName + '/' + homeClubId">
                <PrimaryButton
                  :text="'Discover ' + club.clubNameShort"
                  text-size="'text-sm xs:text-lg sm:text-lg'"
                />
              </router-link>
            </div>
          </div>
        </div>

        <!-- <img
          class="object-cover mt-6"
          src="/img/thegrove3.jpg"
          style="height: 320px; width: 465px;"
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Package3 from '../../package/package3.vue';
import LoadingSpinner from '../../base/loadingSpinner.vue';
import PrimaryButton from '../../base/button/primaryButton.vue';
import services from '@/app/shared/services';

import { nodeEnv } from '@/environment/environment';

export default {

  components: {
    LoadingSpinner,
    Package3,
    PrimaryButton,
  },

  props: {
    availablePackages: {
      type: Object,
      default: undefined,
    },
  },

  data() {
    return {
      overlay: false,
      promocode: '',
      clubId: undefined,
      currentSubscription: undefined,
      environment: undefined,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  computed: {
    ...mapState({
      basket: state => state.basket.basket,
      refreshingBasket: state => state.basket.refreshingBasket,
      homeClubId: state => state.user.homeClubId,
      homeClubName: state => state.user.homeClubName,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
    }),

  },

  watch: {
    basket(value) {
      if (value !== undefined && this.packageGroups === undefined) {
        this.getPackages();
      }
    },

    availablePackages() {
      if (this.availablePackages !== undefined) {
        this.packageGroups = this.availablePackages.packageGroups;
      }
    },
  },

  mounted() {
    this.environment = nodeEnv;

    services.payment.getActiveSubscription().then((data) => {
      this.currentSubscription = data;
    });
  },

  methods: {
    getClubBackground() {
      return `background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), url('${this.currentTheme.renew.club}'); background-size: cover; background-position: center;`;
    },

    getBackground() {
      return `background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), url('${this.currentTheme.renew.body}'); background-size: cover; background-position: center;`;
    },

    getRenewalBackground() {
      return `background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), url('${this.currentTheme.renew.packages}'); background-size: cover; background-position: center;`;
    },

    applyPromo() {
      this.packageGroups = undefined;
      this.getPackages();
    },

    openBasket() {
      this.$store.dispatch('setBasketMenuState', true);
    },

    isInBasket(clubPackage) {
      if (this.basket !== undefined && this.basket !== null && this.basket.BundleId === clubPackage.Id) {
        return true;
      }
      return false;
    },

    getPackages() {
      services.checkout.availablePackages(this.promocode, this.clubId).then((data) => {
        this.packageGroups = data.PackageGroups;
      });
    },
  },
};

</script>

<style>
  @media (max-width: 640px){
    .clubBackground {
      background: none !important;
    }
  }

  @media (min-width: 768px){
    .descBackground {
      background: none !important;
      background-color: #f3f3f3 !important;
    }
  }

</style>
