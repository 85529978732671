<template>
  <div>
    <div
      v-if="availablePackages !== undefined && availablePackages !== null"
      class="text-gray-800 max-w-5xl ml-auto mr-auto mt-6"
    >
      <div
        class="pt-8 sm:pt-16 text-gray-400 overflow-hidden bg-center bg-cover"
        :style="'background-image: linear-gradient(rgba(21,26,47,0.2),rgba(21, 26, 47, 0.5)),url(' + currentTheme.topup.header + ');'"
      >
        <div
          class="text-2xl xs:text-3xl md:text-4xl text-gray-200 pt-2 pr-10 pl-10 pb-12 sm:pb-20 brand-heading"
        >
          {{ club.topup.title }}
        </div>
      </div>

      <div class="">
        <div class="flex justify-center pt-8 xs:pt-16 pb-16 ">
          <img
            class="object-cover hidden xs:inline-block w-8/12"
            :src="currentTheme.topup.body"
            style="height: 490px;"
          >

          <div
            class="background border border-gray-300 xs:border-none shadow-md xs:shadow-none p-4 xs:p-10 xs:m-24 xs:mt-20 ml-0 xs:-ml-32 xs:pr-0 xs:mr-0"
          >
            <div class="border border-gray-200 p-4 xs:border-none xs:p-0 text-xl xs:text-2xl text-gray-800 brand-heading">
              <span class="text-brand-800 text-2xl xs:text-3xl">A</span>dditional points can be spent on rounds of golf at {{ homeClubName }}. Any points remaining can be carried over to next year's renewal.

              <div class="flex mt-4 -ml-2 mr-2">
                <TopUpCard
                  v-for="homePackage in availablePackages.HomePackages"
                  :key="homePackage.BundleId"
                  :club-package="homePackage"
                  :promocode="promocode"
                  :home-club-id="homeClubId"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="w-full min-w-full mt-8 mb-4"
    >
      <div class="ml-auto mr-auto text-center">
        <LoadingSpinner />
        <div class="mt-2 text-sm brand-body">
          Loading the topup packages
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapGetters } from 'vuex';

import { nodeEnv } from '@/environment/environment';
import LoadingSpinner from '../../base/loadingSpinner.vue';
import TopUpCard from './topUpCard.vue';

export default {

  components: {
    LoadingSpinner,
    TopUpCard,
  },

  props: {
    availablePackages: {
      type: Object,
      default: undefined,
    },
  },


  data() {
    return {
      promocode: '',

      environment: undefined,
      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  computed: {
    ...mapState({
      homeClubId: state => state.user.homeClubId,
      homeClubName: state => state.user.homeClubName,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
    }),
  },

  mounted() {
    this.environment = nodeEnv;
  },

  methods: {
    getBackground() {
      return `background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), url(${process.env.VUE_APP_BASE_URL}'img/thegrove7.jpg'); background-size: cover; background-position: center;`;
    },

  },
};
</script>

<style>
  @media (min-width: 640px){
    .background {
      background-color: #f3f3f3;
    }
  }

  @media (max-width: 640px){
    .background {
      background-color: #ffffff;
    }
  }
</style>
