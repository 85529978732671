<template>
  <div
    v-if="!closeBanner"
    class="w-full min-w-full xs:flex items-center justify-between bg-white border-t p-4 pl-5 pr-5"
  >
    <div class="brand-heading text-brand-500 text-center xs:text-left mb-2 xs:mb-0 text-xl sm:text-2xl">
      This website uses cookies to enhance the user experience.
    </div>

    <div>
      <PrimaryButton
        :text="'Close'"
        class="pl-3 pr-3 w-full xs:w-auto"
        :text-size="'text-xl'"
        @click="close"
      />
    </div>
  </div>
</template>

<script>
import PrimaryButton from './button/primaryButton.vue';

export default {

  components: {
    PrimaryButton,
  },

  data() {
    return {
      closeBanner: false,
    };
  },

  methods: {
    close() {
      this.closeBanner = true;
      localStorage.setItem('cookieClose', JSON.stringify(true));
    },
  },

};

</script>
