const accountRoutes = [
  {
    path: '/account',
    redirect: 'account/profile',
    meta: {
      title: 'My Account',
    },
  },
  {
    path: '/account/profile',
    name: 'profile',
    component: () => import('./profile/profile.vue'),
    meta: {
      title: 'My Account',
    },
  },
  {
    path: '/account/profile/:view',
    name: 'profileView',
    component: () => import('./profile/profile.vue'),
    meta: {
      title: 'My Account',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./login/login.vue'),
    meta: {
      title: 'Login',
      noLayout: true,
      requiresAuth: false,
    },
  },
  {
    path: '/login/:clubName/:clubId',
    name: 'loginForClub',
    component: () => import('./login/login.vue'),
    meta: {
      title: 'Login',
      noLayout: true,
      overrideStyle: true,
      requiresAuth: false,
    },
  },
  {
    path: '/Arrears',
    name: 'arrears',
    component: () => import('./arrears/arrears.vue'),
    meta: {
      title: 'Account Locked',
    },
  },
  {
    path: '/login/*',
    name: 'loginBad',
    redirect: 'login',
  },
];

export default accountRoutes;
