<template>
  <div>
    <div
      class="relative bg-white ml-auto mr-auto brand-heading border cursor-pointer"
      @click="openBookingDetails = true"
    >
      <div
        class="w-full bg-center bg-cover h-32 sm:h-56 text-gray-100 brand-body text-sm sm:text-xl p-2"
        :class="getBackground('booking' + booking.Id, currentTheme.coverImage)"
      >
        <div class="relative tracking-wider h-full min-h-full">
          <div class="flex justify-center items-center h-full">
            <div class="text-center">
              <img
                class="h-7 sm:h-10 ml-auto mr-auto"
                :src="currentTheme.loginLogo"
              >
              <div class="mt-2 sm:mt-6">
                {{ booking.CourseName }}
              </div>
              <div style="opacity: 80%;">
                {{ booking.Date | moment("HH:mm Do MMMM YYYY") }}
              </div>
              <div
                v-if="booking.Cancelled"
                class="text-white text-xs xs:text-base bg-red-600 inline-block pl-2 pr-2"
                style="opacity: 90%;"
              >
                Cancelled
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="hidden items-center justify-between">
        <div class="p-3 pl-4">
          <div class="text-brand-600">
            Club
          </div>
          <div
            class="text-gray-700"
            :class="(booking.Cancelled)?'line-through':''"
          >
            {{ booking.ClubName }}
          </div>
        </div>

        <div class="p-3 pl-4">
          <div class="text-brand-600">
            Course
          </div>
          <div
            class="text-gray-700"
            :class="(booking.Cancelled)?'line-through':''"
          >
            {{ booking.CourseName }}
          </div>
        </div>

        <div class="p-3 pl-4">
          <div class="text-brand-600">
            Date
          </div>
          <div
            class="text-gray-700"
            :class="(booking.Cancelled)?'line-through':''"
          >
            {{ booking.Date | moment("h:mma dddd, MMMM Do YYYY") }}
          </div>
        </div>

        <div class="p-3 pl-4">
          <div class="text-brand-600">
            Players
          </div>
          <div
            class="text-gray-700"
            :class="(booking.Cancelled)?'line-through':''"
          >
            {{ booking.NumberOfPeople }}
          </div>
        </div>

        <div class="p-3 pl-4 pr-4">
          <div class="text-brand-600">
            Booked by
          </div>
          <div
            class="text-gray-700"
            :class="(booking.Cancelled)?'line-through':''"
          >
            {{ booking.BookedByUsername }}
          </div>
        </div>
      </div>

      <div
        v-if="booking.Cancellable || booking.Expired"
        class="hidden p-1 select-none"
        style="background-color: #f6f6f6"
      >
        <div
          v-if="booking.Cancellable"
          class="m-4 mt-2 mb-2 mr-2 ml-2"
        >
          <div
            class="inline-block cursor-pointer uppercase text-white brand-button text-center p-1 pl-3 pr-3
            hover:text-white border-brand-800 transition-bg transition-200 transition-ease-in-out"
            @click="$parent.cancelBookingStates.openModal = true; $parent.cancelBookingStates.bookingToCancel = booking"
          >
            Cancel Booking
          </div>
        </div>
        <div
          v-if="booking.Expired"
          class="m-4 mt-2 mb-2"
        >
          <div
            class="inline-block cursor-pointer uppercase text-white brand-button text-center p-1 pl-3 pr-3
            hover:text-white border-brand-800 transition-bg transition-200 transition-ease-in-out"
            @click="bookAgain()"
          >
            Book Again
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="openBookingDetails"
      class="fixed bottom-0 inset-x-0 xxxs:px-1 xxs:px-4 pb-6 z-40 sm:inset-0 sm:p-0 flex sm:items-center sm:justify-center brand-body"
    >
      <div
        x-transition:enter="ease-out duration-300"
        x-transition:enter-start="opacity-0"
        x-transition:enter-end="opacity-100"
        x-transition:leave="ease-in duration-200"
        x-transition:leave-start="opacity-100"
        x-transition:leave-end="opacity-0"
        class="fixed inset-0 transition-opacity"
      >
        <div class="absolute inset-0 z-20 bg-gray-500 opacity-75" />
      </div>

      <div
        x-transition:enter="ease-out duration-300"
        x-transition:enter-start="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        x-transition:enter-end="opacity-100 translate-y-0 sm:scale-100"
        x-transition:leave="ease-in duration-200"
        x-transition:leave-start="opacity-100 translate-y-0 sm:scale-100"
        x-transition:leave-end="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        class="bg-white px-4 pt-5 shadow-xl transform transition-all w-full sm:p-6 sm:pb-3 z-30 sm:max-w-xl"
      >
        <div>
          <div class="text-center mt-1">
            <div>
              <div
                class="brand-body"
              >
                <div
                  class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fad"
                    data-icon="calendar-day"
                    class="w-6 h-6 text-green-600"
                    role="img"
                    viewBox="0 0 448 512"
                  ><g class="fa-group"><path
                    class="fa-secondary"
                    fill="currentColor"
                    d="M0 192v272a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48V192zm192 176a16 16 0 0 1-16 16H80a16 16 0 0 1-16-16v-96a16 16 0 0 1 16-16h96a16 16 0 0 1 16 16zm112-240h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16zm-192 0h32a16 16 0 0 0 16-16V16a16 16 0 0 0-16-16h-32a16 16 0 0 0-16 16v96a16 16 0 0 0 16 16z"
                    opacity="0.4"
                  /><path
                    class="fa-primary"
                    fill="currentColor"
                    d="M448 112v80H0v-80a48 48 0 0 1 48-48h48v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h128v48a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V64h48a48 48 0 0 1 48 48z"
                  /></g></svg>
                </div>

                <h3 class="text-2xl leading-6 font-medium text-gray-900 brand-heading mb-4 sm:mb-6 mt-4">
                  Booking Details
                </h3>

                <!-- <div class="mt-4">
                  <div class="text-base leading-5 text-gray-600">
                    Your booking has been successful!
                  </div>
                </div>
                   -->

                <div class="mt-2">
                  <div class="leading-tight">
                    {{ booking.ClubName }}
                  </div>
                  <div class="leading-tight">
                    {{ booking.CourseName }} - {{ booking.NumberOfPeople }} player<span v-if="booking.NumberOfPeople > 1">s</span> <span v-if="OfWhichJuniors > 0">({{ booking.OfWhichJuniors }} juniors)</span>
                  </div>
                  <div class="text-xl sm:text-2xl tracking-wide text-brand-500 leading-none mt-1">
                    {{ booking.Date | moment("h:mma dddd, MMMM Do YYYY") }}
                  </div>
                </div>

                <div class="mt-3">
                  <div class="leading-tight">
                    Booked by
                  </div>
                  <div class="text-xl sm:text-2xl tracking-wide text-brand-500 leading-none mt-1">
                    {{ booking.BookedByUsername }}
                  </div>
                </div>

                <div class="mt-3">
                  <div class="leading-tight">
                    Booking code
                  </div>
                  <div class="text-xl sm:text-2xl tracking-wide text-brand-500 leading-none mt-1">
                    {{ booking.BookingRef }}
                  </div>
                </div>

                <div class="mt-3">
                  <div class="leading-tight">
                    Confirmation code
                  </div>
                  <div class="text-xl sm:text-2xl tracking-wide text-brand-500 leading-none mt-1">
                    {{ booking.ConfirmationCode }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="booking.Expired || !booking.Cancellable || booking.Cancelled"
          class="mt-5 sm:mt-8"
        >
          <div class="inline-block w-1/2 pr-2">
            <SecondaryButton
              :text="'Book again'"
              @click="bookAgain()"
            />
          </div>

          <div class="inline-block w-1/2 pl-2">
            <PrimaryButton
              :text="'Close'"
              @click="openBookingDetails = false"
            />
          </div>
        </div>

        <div
          v-else
          class="mt-5 sm:mt-8 flex justify-center items-center"
        >
          <div class="inline-block w-1/2 pr-2">
            <SecondaryButton
              :text="'Cancel Booking'"
              @click="$parent.cancelBookingStates.openModal = true; $parent.cancelBookingStates.bookingToCancel = booking; openBookingDetails = false;"
            />
          </div>

          <div class="inline-block w-1/2 pl-2">
            <PrimaryButton
              :text="'Close'"
              @click="openBookingDetails = false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import router from '../../../app-routes';
import PrimaryButton from '../base/button/primaryButton.vue';
import SecondaryButton from '../base/button/secondaryButton.vue';

export default {

  components: {
    PrimaryButton,
    SecondaryButton,
  },

  props: {
    booking: {
      type: Object,
      default: null,
    },
  },


  data() {
    return {
      openBookingDetails: false,
      backgroundLoaded: false,
    };
  },

  computed: {
    ...mapGetters({
      currentTheme: 'getCurrentTheme',
    }),
  },

  created() {
    this.$store.commit('setOverridingClubId', this.clubId);
  },

  methods: {
    getBackground(className, image) {
      if (!this.backgroundLoaded) {
        this.backgroundLoaded = true;
        const style = document.createElement('style');
        style.type = 'text/css';
        style.innerHTML = `.${className} { background: linear-gradient(rgba(19, 33, 48, 0.1), rgba(19, 33, 48, 0.4)), url(${image}); background-position: center; background-size: cover; background-size: 100%; transition: all 1s ease; -moz-transition: all 1s ease; -ms-transition: all 1s ease; -webkit-transition: all 1s ease; -o-transition: all 1s ease; } .${className}:hover { background-size: 105%; }`;
        document.getElementsByTagName('head')[0].appendChild(style);
      }

      return className;
    },

    bookAgain() {
      router.push(`/booking/clubs/${this.booking.ClubId}/${this.booking.CourseId}?Date=${this.booking.Date}&Players=${this.booking.NumberOfPeople}&Juniors=${this.booking.OfWhichJuniors}&BookAgain=true`);
    },
  },

};
</script>
