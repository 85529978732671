
<!-- Template -->
<template>
  <div class="flex items-center justify-center h-full min-h-screen">
    <div class="-mt-16 sm:-mt-32 max-w-2xl p-4">
      <div class="uppercase bg-brand-500 inline-block p-1 pl-3 pr-3 brand-heading text-white text-lg">
        Page not found
      </div>
      <div class="brand-heading text-4xl xxxs:text-5xl xxs:text-6xl text-brand-500 mt-2 mb-4">
        Oops! Error 404
      </div>

      <div>
        <div class="text-lg xs:text-xl text-gray-600 brand-body">
          Sorry, we can't seem to find the page you are looking for. Please return to the homepage or submit a bug report if you think something is broken.
        </div>
        <div class="xxs:inline-block mt-4">
          <div class="w-full xxs:flex">
            <PrimaryButton
              :padding="'px-3 py-2'"
              :text="'Back to home'"
              @click="backToHome"
            />
            <div v-if="nodeEnv !== 'cordova'">
              <SecondaryButton
                class="mt-4 xxs:mt-0 xxs:ml-6"
                :padding="'px-3 py-2'"
                :text="'Submit a bug report'"
                @click="submitBugReport"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import PrimaryButton from '@/app/shared/components/base/button/primaryButton.vue';
import SecondaryButton from '@/app/shared/components/base/button/secondaryButton.vue';

export default {

  components: {
    PrimaryButton,
    SecondaryButton,
  },


  data() {
    return {
      nodeEnv: '',
    };
  },

  mounted() {
    this.nodeEnv = process.env.NODE_ENV;
  },

  methods: {
    backToHome() {
      this.$router.push('/');
    },

    submitBugReport() {
      window.open('https://form.asana.com/?k=lI-mNFCMqYpcU0FLrhC3dQ&d=1163201844111955');
      this.$router.push('/');
    },
  },
};
</script>
