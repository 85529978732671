import buddyManagement from './buddyManagement/buddyManagement.vue';

const buddyRoutes = [
  {
    path: '/Buddies',
    component: buddyManagement,
    meta: {
      title: 'My Buddies',
    },
  },
];

export default buddyRoutes;
