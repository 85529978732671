<template>
  <div
    class="relative buddyCardBg z-10 xs:-mt-24 max-w-5xl ml-auto mr-auto xs:shadow-lg"
  >
    <div class="xxs:flex w-full min-w-full">
      <div
        class="hidden xs:flex items-center justify-center h-24 xxs:h-32 w-full xxs:w-auto xs:h-40 align-top bg-brand-500 object-cover shadow-md select-none"
      >
        <div
          v-if="forename !== undefined"
          class="ml-12 mr-12 xs:ml-24 xs:mr-24 mb-1 text-white text-6xl font-thin defaultFont"
        >
          {{ forename.charAt(0) }}{{ surname.charAt(0) }}
        </div>
      </div>

      <div class="xs:inline-block p-6 xs:p-4 ml-2 mr-2 flex-grow">
        <div class="flex justify-between items-center">
          <div
            v-if="forename !== undefined"
            class="inline-block text-gray-800 brand-heading text-center xxs:text-left text-2xl tracking-wide"
          >
            {{ forename }} {{ surname }}
          </div>

          <!-- <div class="inline-block text-sm xs:text-base">
            <div class="text-gray-600 uppercase tracking-widest defaultFont">
              <div class="inline text-brand-800">
                &bull;
              </div>
              Premium
            </div>
          </div> -->
        </div>

        <transition name="fade">
          <div
            v-if="shareToken !== undefined"
            class="defaultFont"
          >
            <div class="uppercase tracking-widest text-gray-600 text-sm mt-4">
              Your buddy share code
            </div>

            <div class="flex items-center">
              <div class="inline-block text-2xl text-gray-800 tracking-wider">
                {{ shareToken }}
              </div>
              <div
                class="group inline-block ml-2 border rounded-full cursor-pointer bg-white hover:bg-gray-100 text-gray-800 hover:text-gray-700"
                style="padding: 2px 7px; border-radius: 50%;"
                @click="copyCodeToClipboard"
              >
                <svg
                  v-if="!copied"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="copy"
                  class="w-4 h-4"
                  style="margin-top: 3px; margin-bottom: 3px"
                  role="img"
                  viewBox="0 0 448 512"
                ><path
                  fill="currentColor"
                  d="M320 448v40c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V120c0-13.255 10.745-24 24-24h72v296c0 30.879 25.121 56 56 56h168zm0-344V0H152c-13.255 0-24 10.745-24 24v368c0 13.255 10.745 24 24 24h272c13.255 0 24-10.745 24-24V128H344c-13.2 0-24-10.8-24-24zm120.971-31.029L375.029 7.029A24 24 0 0 0 358.059 0H352v96h96v-6.059a24 24 0 0 0-7.029-16.97z"
                /></svg>
                <svg
                  v-if="copied"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="check"
                  class="w-4 h-4 text-green-600"
                  style="margin-top: 3px; margin-bottom: 3px"
                  role="img"
                  viewBox="0 0 512 512"
                ><path
                  fill="currentColor"
                  d="M435.848 83.466L172.804 346.51l-96.652-96.652c-4.686-4.686-12.284-4.686-16.971 0l-28.284 28.284c-4.686 4.686-4.686 12.284 0 16.971l133.421 133.421c4.686 4.686 12.284 4.686 16.971 0l299.813-299.813c4.686-4.686 4.686-12.284 0-16.971l-28.284-28.284c-4.686-4.686-12.284-4.686-16.97 0z"
                /></svg>
              </div>

              <div
                class="group inline-block ml-1 border rounded-full cursor-pointer bg-white hover:bg-gray-100 text-gray-800 hover:text-gray-700"
                style="padding: 2px 11px; border-radius: 50%;"
                @click="openShareCodeInfo = true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="info"
                  class="w-2 h-4"
                  style="margin-top: 3px; margin-bottom: 3px;"
                  role="img"
                  viewBox="0 0 192 512"
                ><path
                  fill="currentColor"
                  d="M20 424.229h20V279.771H20c-11.046 0-20-8.954-20-20V212c0-11.046 8.954-20 20-20h112c11.046 0 20 8.954 20 20v212.229h20c11.046 0 20 8.954 20 20V492c0 11.046-8.954 20-20 20H20c-11.046 0-20-8.954-20-20v-47.771c0-11.046 8.954-20 20-20zM96 0C56.235 0 24 32.235 24 72s32.235 72 72 72 72-32.235 72-72S135.764 0 96 0z"
                /></svg>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <ShareCodeInfo v-if="openShareCodeInfo" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import services from '@/app/shared/services';
import ShareCodeInfo from './shareCodeInfo.vue';

export default {

  components: {
    ShareCodeInfo,
  },

  data() {
    return {
      shareToken: undefined,
      copied: false,
      openShareCodeInfo: false,
    };
  },

  computed: {
    ...mapState({
      forename: state => state.user.forename,
      surname: state => state.user.surname,
    }),
  },

  mounted() {
    services.buddy.buddyShareToken().then((result) => {
      this.shareToken = result;
    });
  },

  methods: {
    copyCodeToClipboard() {
      const textArea = document.createElement('textarea');

      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = 0;
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      textArea.value = this.shareToken;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        document.execCommand('copy');
        // const msg = successful ? 'successful' : 'unsuccessful';
        this.copied = true;
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      document.body.removeChild(textArea);
    },
  },


};


</script>

<style>
  @media (min-width: 640px){
    .buddyCardBg {
      background-image: linear-gradient(rgba(255, 255, 255, 0.84),rgba(255,255,255,0.98), rgba(255,255,255,1))
    }
  }

  @media (max-width: 640px){
    .buddyCardBg {
      background-color: rgb(243, 243, 243)
    }
  }

</style>
