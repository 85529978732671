

class ClubTheme {
  constructor(params) {
    this.clubId = params.clubId;
    this.fonts = params.fonts;
    this.quotesTemplate = params.quotesTemplate;

    const pathToImages = `${process.env.VUE_APP_BASE_URL}img/club${this.clubId}/`;

    this.cssTheme = `club${this.clubId}`;
    this.loginLogo = params.loginLogo === undefined
      ? `${pathToImages}logoLarge.png`
      : `${pathToImages}${params.loginLogo}`;
    this.loginLogoLight = params.loginLogoWhite === undefined
      ? `${pathToImages}logoLarge.png`
      : `${pathToImages}${params.loginLogoWhite}`;
    this.loginLogoAlternative = params.loginLogoAlternative === undefined
      ? `${pathToImages}logoLarge.png`
      : `${pathToImages}${params.loginLogoAlternative}`;
    this.footerBackground = params.footerBackground === undefined
      ? ''
      : `${pathToImages}${params.footerBackground}`;
    this.coverImage = params.coverImage === undefined
      ? `${pathToImages}cover.jpg`
      : `${pathToImages}${params.coverImage}`;
    this.coverImage2 = params.coverImage === undefined
      ? `${pathToImages}cover2.jpg`
      : `${pathToImages}${params.coverImage}`;
    this.coverImage3 = params.coverImage === undefined
      ? `${pathToImages}cover3.jpg`
      : `${pathToImages}${params.coverImage}`;
    this.coverImage4 = params.coverImage === undefined
      ? `${pathToImages}cover4.jpg`
      : `${pathToImages}${params.coverImage}`;
    this.landing = {
      logo: `${pathToImages}landing/xviii.jpg`,
      welcome: `${pathToImages}landing/welcome.jpg`,
      membership: `${pathToImages}landing/membership.jpg`,
      seperator: `${pathToImages}landing/seperator.jpg`,
      leftmostColumn: `${pathToImages}landing/leftmostColumn.jpg`,
      middleColumn: `${pathToImages}landing/middleColumn.jpg`,
      rightmostColumn: `${pathToImages}landing/rightmostColumn.jpg`,
      package1: `${pathToImages}landing/package1.jpg`,
      package2: `${pathToImages}landing/package2.jpg`,
      map: `${pathToImages}landing/map.png`,
    };
    this.home = {
      welcome: `${pathToImages}home/welcome.jpg`,
      teetimes: `${pathToImages}home/teetimes.jpg`,
      history: `${pathToImages}home/history.jpg`,
      topup: `${pathToImages}home/topup.jpg`,
    };
    this.renew = {
      header: `${pathToImages}renew/header.jpg`,
      packages: `${pathToImages}renew/packages.jpg`,
      body: `${pathToImages}renew/body.jpg`,
      club: `${pathToImages}renew/club.jpg`,
    };
    this.topup = {
      header: `${pathToImages}topup/header.jpg`,
      body: `${pathToImages}topup/body.jpg`,
    };
  }
}

export { ClubTheme };
