import { clubList } from './lists/clubList';

const clubStore = {
  state: {
    overrideClub: false,
    overridingClubId: 0,
  },

  getters: {
    getClub: (state, getters, rootState) => {
      if (state.overrideClub) {
        return clubList.find(club => club.clubId === state.overridingClubId) !== undefined
          ? clubList.find(club => club.clubId === state.overridingClubId)
          : clubList.find(club => club.clubId === 0);
      }
      return clubList.find(club => club.clubId === rootState.user.homeClubId) !== undefined
        ? clubList.find(club => club.clubId === rootState.user.homeClubId)
        : clubList.find(club => club.clubId === 0);
    },
  },

  mutations: {
    setOverrideStyle(state, value) {
      if (typeof value === 'number') {
        const favicon = document.getElementById('favicon');
        favicon.href = `/img/icons/${value}.ico`;
      }

      state.overrideClub = value;
    },

    setOverridingClubId(state, value) {
      if (!(isNaN(value))) {
        state.overridingClubId = Number(value);
      } else {
        state.overridingClubId = 0;
      }
    },
  },

  actions: {

  },
};

export { clubStore };
