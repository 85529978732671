<template>
  <div
    class="packageName bg-white shadow-lg border p-5 text-brand-500 text-center brand-heading inline-block"
    style="max-width: 430px;"
  >
    <div class="border border-gray-200 p-4 pl-6 pr-6">
      <div
        v-if="clubPackage !== undefined"
        class="packageName text-3xl brand-heading text-brand-500"
      >
        <span class="text-4xl">{{ clubPackage.Name.charAt(0) }}</span>{{ clubPackage.Name.substring(1) }} Package
      </div>

      <div class="flex items-baseline justify-center text-center">
        <div class="packagePrice text-4xl text-gray-700">
          {{ clubPackage.Bundles[0].Currency.CurrencySymbol }}{{ clubPackage.Bundles[0].Cost.toLocaleString() }}
        </div>
        <div class="text-base ml-1">
          / year
        </div>
      </div>

      <div class="packageBenefits brand-body text-lg mt-2 mb-2">
        {{ clubPackage.Description }}
      </div>

      <div>
        <div
          v-if="!clubPackage.IsFull || completedFirstPayment"
          class="inline-block"
        >
          <div
            v-if="isInBasket"
            class="inline-block text-lg text-white cursor-pointer brand-button text-center p-1 mt-4 mb-2
            hover:text-white border-brand-800 transition-bg transition-200 transition-ease-in-out  select-none"
            style="width: 180px;"
            @click="emptyBasket"
          >
            <div
              v-if="!loading"
              class="inline-block"
            >
              Remove
            </div>
            <LoadingSpinner
              v-if="loading"
              :white="true"
              class="inline-block text-white"
            />
          </div>

          <div
            v-else
            class="addToBasket inline-block text-lg w-42 text-white cursor-pointer brand-button text-center p-1 pl-3 pr-3 mt-4 mb-2
            hover:text-white border-brand-800 transition-bg transition-200 transition-ease-in-out  select-none"
            style="width: 180px;"
            @click="setBasket"
          >
            <div
              v-if="!loading"
              class="inline-block"
            >
              Add to Basket
            </div>
            <LoadingSpinner
              v-if="loading"
              :white="true"
              class="inline-block text-white"
            />
          </div>
        </div>

        <a
          v-else
          :href="'mailto:group.golf@thegrove.co.uk?subject=' + clubPackage.Name + ' Package Waiting List&body=I would like to register my interest for the ' + clubPackage.Name + ' package, notify me via this email address when there is availability.'"
        >
          <div
            class="addToBasket inline-block text-lg text-white cursor-pointer brand-button text-center p-1 pl-3 pr-3 mt-4 mb-2
            hover:text-white border-brand-800 transition-bg transition-200 transition-ease-in-out  select-none"
            style="width: 250px;"
          >
            <div
              class="inline-block"
            >
              Join our Waiting List
            </div>
          </div>
        </a>

        <div
          v-if="!clubPackage.IsFull || completedFirstPayment"
          class="xxs:inline-block text-lg"
        >
          <router-link :to="/Club/ + club.clubName + '/' + homeClubId + '#package' + index">
            <div class="xxs:ml-6 cursor-pointer hover:text-secondary-500 transition-all duration-300">
              See all benefits
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { SetBasketModel } from 'pmg-services';
import { nodeEnv } from '@/environment/environment';
import LoadingSpinner from '../base/loadingSpinner.vue';

export default {
  name: 'Package',

  components: {
    LoadingSpinner,
  },

  props: {
    index: {
      type: Number,
      default: undefined,
    },
    clubPackage: {
      type: Object,
      default: null,
    },
    promocode: {
      type: String,
      default: '',
    },
    homeClubId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      environment: undefined,
      selectedBundle: undefined,
    };
  },

  computed: {
    ...mapState({
      basket: state => state.basket.basket,
      homeClubName: state => state.user.homeClubName,
      completedFirstPayment: state => state.user.completedFirstPayment,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
    }),

    isInBasket() {
      if (this.basket !== undefined && this.basket !== null && this.selectedBundle !== undefined && this.basket.BundleId === this.selectedBundle.Id) {
        return true;
      }
      return false;
    },
  },

  mounted() {
    this.environment = nodeEnv;

    this.selectedBundle = this.clubPackage.Bundles[0];
  },

  methods: {
    setBasket() {
      this.loading = true;

      const bundle = new SetBasketModel(this.selectedBundle.Id, this.promocode, this.homeClubId, null);
      this.$store.dispatch('setBasketBundle', bundle).then(() => {
        this.$emit('basketSet');
        this.$store.dispatch('setBasketMenuState', true);
        this.loading = false;
      });
    },

    emptyBasket() {
      this.loading = true;
      this.$store.dispatch('emptyBasket').then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>
@media (max-width: 500px){
  .packageBackground {
    background: none !important;
  }
}
</style>
