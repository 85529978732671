<template>
  <div
    class="bg-white text-center text-gray-800 brand-button text-sm"
    style="z-index: 20;"
  >
    <div
      class="p-2 w-1/5 inline-block transition-all transition-300"
      :class="isCurrentView('Home')?'bg-brand-500 text-white':''"
      @click="changeView('/', 'Home')"
    >
      <div class="text-lg leading-none">
        <!-- Icon -->
      </div>
      <div class="leading-none mt-1">
        Home
      </div>
    </div>

    <div
      class="p-2 w-1/5 inline-block transition-all transition-300"
      :class="isCurrentView('Book')?'bg-brand-500 text-white':''"
      @click="changeView('/booking/clubs/259', 'Book')"
    >
      <div class="text-lg leading-none">
        <!-- Icon -->
      </div>
      <div class="leading-none mt-1">
        Book
      </div>
    </div>

    <div
      class="p-2 w-1/5 inline-block transition-all transition-300"
      :class="isCurrentView('Buddies')?'bg-brand-500 text-white':''"
      @click="changeView('/Buddies', 'Buddies')"
    >
      <div class="text-lg leading-none">
        <!-- Icon -->
      </div>
      <div class="leading-none mt-1">
        Buddies
      </div>
    </div>

    <div
      class="p-2 w-1/5 inline-block transition-all transition-300"
      :class="isCurrentView('Account')?'bg-brand-500 text-white':''"
      @click="changeView('/account/profile', 'Account')"
    >
      <div class="text-lg leading-none">
        <!-- Icon -->
      </div>
      <div class="leading-none mt-1">
        Account
      </div>
    </div>

    <div
      class="p-2 w-1/5 inline-block transition-all transition-300"
      :class="isCurrentView('Help')?'bg-brand-500 text-white':''"
      @click="changeView('/Help', 'Help')"
    >
      <div class="text-lg leading-none">
        <!-- Icon -->
      </div>
      <div class="leading-none mt-1">
        Help
      </div>
    </div>
  </div>
</template>

<script>
import router from '../../../../app-routes';

export default {
  data() {
    return {
      currentView: 'Home',
    };
  },


  watch: {
    $route(to) {
      if (to.path === '/') {
        this.currentView = 'Home';
      } else if (to.path.includes('booking')) {
        this.currentView = 'Book';
      } else if (to.path.includes('Buddies')) {
        this.currentView = 'Buddies';
      } else if (to.path.includes('account')) {
        this.currentView = 'Account';
      } else if (to.path.includes('Help')) {
        this.currentView = 'Help';
      }
    },
  },

  created() {
    const { path } = this.$router.currentRoute;

    if (path === '/') {
      this.currentView = 'Home';
    } else if (path.includes('booking')) {
      this.currentView = 'Book';
    } else if (path.includes('Buddies')) {
      this.currentView = 'Buddies';
    } else if (path.includes('account')) {
      this.currentView = 'Account';
    } else if (path.includes('Help')) {
      this.currentView = 'Help';
    }
  },

  methods: {
    isCurrentView(view) {
      if (view === this.currentView) {
        return true;
      }
      return false;
    },

    changeView(route, view) {
      if (this.$router.currentRoute.path !== route) {
        this.currentView = view;
        router.push(route);
      }
    },
  },
};
</script>
