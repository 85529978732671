const environment = process.env.VUE_APP_API_URL;
const siteUrl = process.env.VUE_APP_SITE_URL;
// const apiBaseUrl = VUE_APP_API_BASE_URL;
const apiBaseUrl = `${environment}/api/`;
const nodeEnv = process.env.NODE_ENV;
const adminUrl = process.env.VUE_APP_ADMIN_URL;
const stripeAPIKey = process.env.VUE_APP_STRIPE_API_KEY;

export {
  environment, apiBaseUrl, adminUrl, stripeAPIKey, nodeEnv, siteUrl,
};
