import help from './help.vue';

const buddyRoutes = [
  {
    path: '/Help',
    component: help,
    meta: {
      title: 'Help',
    },
  },
  {
    path: '/Help/:clubName/:clubId',
    component: help,
    meta: {
      title: 'Help',
      overrideStyle: true,
    },
  },
];

export default buddyRoutes;
