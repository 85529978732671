<template>
  <div class="w-full min-w-full">
    <div
      class="object-cover"
      :style="getBackground()"
    >
      <div class="text-center p-6 xxxs:p-10 xxs:p-16 xs:p-24 md:p-36">
        <transition name="fade">
          <div
            v-if="clubDetails !== null"
            class="p-4 bg-white max-w-3xl ml-auto mr-auto shadow-md"
          >
            <div class="text-base xxxs:text-lg xxs:text-xl xs:text-2xl md:text-3xl lg:text-4xl text-center border p-2 xxs:p-4 xs:p-8 brand-heading h-full">
              <div>
                <div class="text-brand-500">
                  We're here to help
                </div>
                <div class="mt-2">
                  <div class="mt-2 text-gray-700">
                    <span class="text-brand-500">T: </span>{{ clubDetails.Telephone }}
                  </div>
                  <div class="mt-2 text-gray-700">
                    <span class="text-brand-500">E: </span>{{ club.email }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>

        <div
          v-if="clubDetails === null"
          style="height: 225px; width: 225px;"
        />
      </div>
    </div>


    <div class="text-gray-800 max-w-3xl ml-auto mr-auto p-2 pt-6 pb-6">
      <div class="p-1 tracking-wide text-gray-500">
        Frequently asked questions
      </div>
      <div class="shadow-md brand-body">
        <div class="border border-b-0">
          <div
            class="flex items-center justify-between cursor-pointer p-3 pl-4 pr-4 bg-white hover:bg-gray-50"
            @click="openFAQ1 = !openFAQ1"
          >
            <div class="font-semibold brand-heading text-brand-500">
              How do I book a tee time?
            </div>
            <div v-if="!openFAQ1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              /></svg>
            </div>
            <div v-if="openFAQ1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
              /></svg>
            </div>
          </div>

          <div
            v-if="openFAQ1"
            class="bg-gray-50 p-3 pl-4 pr-4 border-t border-gray-200"
          >
            <div>
              You can book a tee-time by logging into your online account and going to the <span><router-link
                to="/Booking/Clubs"
                class="text-brand-500 hover:underline"
              >clubs section</router-link></span>.
            </div>

            <img
              :src="baseUrl + 'img/helpScreenshots/menuClubs.png'"
              class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
            >
            <!-- <img
              v-if="flexiPoints !== 0 && !flexiPointsAllowed"
              src="/img/helpScreenshots/menuBook.png"
              class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
            > -->

            <div
              class="mt-6"
            >
              The club at the top of the list is your home club. Click on the club that you'd like to book at and you'll be taken to the tee times search page.
              <img
                :src="baseUrl + 'img/helpScreenshots/clubList.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            <div class="mt-6">
              Choose the course, how many players and change the date of the booking before you click on search.
              <img
                :src="baseUrl + 'img/helpScreenshots/searchFilters.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            <div class="mt-6">
              Choose a tee time that you would like to play by clicking on it.
              <img
                :src="baseUrl + 'img/helpScreenshots/teeTimeSelection.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            <div class="mt-6">
              Before you select Book Round, you can change your guests to your friends for Buddy Booking and share the points. Check the date, time and course that you have booked. Once you are happy with the booking information click on Book Round.
              <img
                :src="baseUrl + 'img/helpScreenshots/bookingConfirmation.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>
          </div>
        </div>

        <div class="border border-b-0">
          <div
            class="flex items-center justify-between cursor-pointer p-3 pl-4 pr-4 bg-white hover:bg-gray-50"
            @click="openFAQ2 = !openFAQ2"
          >
            <div class="font-semibold brand-heading text-brand-500">
              How do I make a buddy booking?
            </div>
            <div v-if="!openFAQ2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              /></svg>
            </div>
            <div v-if="openFAQ2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
              /></svg>
            </div>
          </div>

          <div
            v-if="openFAQ2"
            class="bg-gray-50 p-3 pl-4 pr-4 border-t border-gray-200"
          >
            <div class="">
              You need to add your buddies through the <a
                v-if="environment !== 'cordova'"
                href="/Buddies"
                target="_blank"
                class="text-blue-700 hover:text-blue-600 hover:underline"
              >buddy management</a>
              <router-link
                v-else
                to="/Buddies"
                class="text-blue-700 hover:text-blue-600 hover:underline"
              >
                buddy management
              </router-link> first before you make a buddy booking.
            </div>

            <div class="mt-6">
              Once you have confirmed your buddies are ready to make a booking. You'll need create a booking in the normal way and add 2 or more players for the tee time. Click search to view the tee times.
              <img
                :src="baseUrl + 'img/helpScreenshots/selectPlayers.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            <div class="mt-6">
              Click a tee time to see the booking confirmation. Here you can replace any guests to the bookings with a buddy by clicking Add Buddy.
              <img
                :src="baseUrl + 'img/helpScreenshots/addBuddyToBooking.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            <div class="mt-6">
              You'll then need to select the buddy you would like to add to the booking.
              <img
                :src="baseUrl + 'img/helpScreenshots/selectBuddy.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>
          </div>
        </div>

        <div class="border border-b-0">
          <div
            class="flex items-center justify-between cursor-pointer p-3 pl-4 pr-4 bg-white hover:bg-gray-50"
            @click="openFAQ3 = !openFAQ3"
          >
            <div class="font-semibold brand-heading text-brand-500">
              How do I cancel a booking?
            </div>
            <div v-if="!openFAQ3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              /></svg>
            </div>
            <div v-if="openFAQ3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
              /></svg>
            </div>
          </div>

          <div
            v-if="openFAQ3"
            class="bg-gray-50 p-3 pl-4 pr-4 border-t border-gray-200"
          >
            <div class="">
              If you can not make your upcoming game, you can cancel via your online account up to 14 days before the booking. Your points will then be automatically credited back to your account.

              You can cancel an upcoming booking through the <a
                v-if="environment !== 'cordova'"
                href="/"
                target="_blank"
                class="text-blue-700 hover:text-blue-600 hover:underline"
              >dashboard</a>
              <router-link
                v-else
                to="/"
                class="text-blue-700 hover:text-blue-600 hover:underline"
              >
                dashboard
              </router-link>
              section at the home page. This is where your upcoming bookings will appear.
              <img
                :src="baseUrl + 'img/helpScreenshots/exampleBooking.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>
          </div>
        </div>

        <div class="border border-b-0">
          <div
            class="flex items-center justify-between cursor-pointer p-3 pl-4 pr-4 bg-white hover:bg-gray-50"
            @click="openFAQ4 = !openFAQ4"
          >
            <div class="font-semibold brand-heading text-brand-500">
              How do I get my handicap?
            </div>
            <div v-if="!openFAQ4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              /></svg>
            </div>
            <div v-if="openFAQ4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
              /></svg>
            </div>
          </div>

          <div
            v-if="openFAQ4"
            class="bg-gray-50 p-3 pl-4 pr-4 border-t border-gray-200"
          >
            In order to receive a handicap, you’ll need to submit 3 scorecards to your home club. Please contact a member of the golf team to find out more.
          </div>
        </div>

        <div class="border border-b-0">
          <div
            class="flex items-center justify-between cursor-pointer p-3 pl-4 pr-4 bg-white hover:bg-gray-50"
            @click="openFAQ5 = !openFAQ5"
          >
            <div class="font-semibold brand-heading text-brand-500">
              How do I add a buddy?
            </div>
            <div v-if="!openFAQ5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              /></svg>
            </div>
            <div v-if="openFAQ5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
              /></svg>
            </div>
          </div>

          <div
            v-if="openFAQ5"
            class="bg-gray-50 p-3 pl-4 pr-4 border-t border-gray-200"
          >
            <div class="">
              You'll need to navigate to the <a
                v-if="environment !== 'cordova'"
                href="/Buddies"
                target="_blank"
                class="text-blue-700 hover:text-blue-600 hover:underline"
              >buddy management page</a>
              <router-link
                v-else
                to="/Buddies"
                class="text-blue-700 hover:text-blue-600 hover:underline"
              >
                buddy management page
              </router-link>
              to add a buddy. You can do this through the main menu.
              <img
                :src="baseUrl + 'img/helpScreenshots/menuBuddies.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            <div class="mt-6">
              Next, click the add buddy button in the bottom right corner of your screen.
              <img
                :src="baseUrl + 'img/helpScreenshots/addBuddyButton.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            <div class="mt-6">
              You need to send a buddy request to your friend. Put their email address or share code in the box and click find buddy.
              <img
                :src="baseUrl + 'img/helpScreenshots/buddyRequest.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            Once the request has been sent, your friend will need to accept the request. You can then book a home game and share the points!
          </div>
        </div>

        <div class="border border-b-0">
          <div
            class="flex items-center justify-between cursor-pointer p-3 pl-4 pr-4 bg-white hover:bg-gray-50"
            @click="openFAQ6 = !openFAQ6"
          >
            <div class="font-semibold brand-heading text-brand-500">
              How are my card details kept secure?
            </div>
            <div v-if="!openFAQ6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              /></svg>
            </div>
            <div v-if="openFAQ6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
              /></svg>
            </div>
          </div>

          <div
            v-if="openFAQ6"
            class="bg-gray-50 p-3 pl-4 pr-4 border-t border-gray-200"
          >
            At {{ club.clubName }} we take payment security extremely seriously. This article explains how our payment process works,
            and why you can trust it with your details.
            <br>
            <br>
            <div class="font-semibold brand-heading text-brand-500">
              How your card details are processed
            </div>
            Like almost all businesses, we use a third party payment processing system to take payments securely.
            This means we do not directly process or save your card details, instead we use the internationally recognised leader in payments, Stripe.
            <br>
            <br>
            When you enter your card details at the checkout, even though you're on our page we cannot see this information, and have no way to access it, you're instead entering your details into an embedded secure portal provided by Stripe. Once this information is entered, we're able to request a payment from it via a process called Tokenisation, where the payment provider gives us a single use "token" to request a payment on this card without sharing the card details.
            <br>
            <br>
            As with other online payments, we use Visa 3D Secure and Mastercard SecureCard, and in addition our payment provider runs fraud and suspicious activity checks in the background to provide extra protection. Our payment process is PCI DSS compliant and use Strong Customer Authentication (SCA).
            <br>
            <br>
            <a
              href="https://stripe.com/gb/payments/elements"
              target="_blank"
              class="text-blue-700 hover:text-blue-600 hover:underline"
              @click="openUrl('https://stripe.com/gb/payments/elements')"
            >More technical information on how card details are taken</a>
            <br>
            <br>
            <div class="font-semibold brand-heading text-brand-500">
              How your card details are stored for reuse
            </div>
            In order to make future payments easier, and power recurring payments, our payment provider stores card details securely. In the same way as above, we have no access to your full card details, however we can see a representation of the cards linked against your account, so we can reuse the cards for payments.
            <br>
            <br>
            In order for you to know which card is being used at checkout, we can retrieve a summary of the cards linked to your account from the payment provider, which are limited to;
            <br>
            <br>
            Card Type (Visa, Mastercard, etc)
            Last 4 digits of the long number
            Expiry Date
            We retrieve this information from the payment provider at checkout time, and we do not store this information. This information is not enough to make a payment.
            <br>
            <br>
            Our payment provider works with banks to ensure card details are kept updated; if your card is replaced due to being expired, lost, or stolen, the payment provider will update the card details on your behalf.
            <br>
            <br>
            When a saved card is used, the same fraud and security checks take place as when it is entered for the first time.
            <br>
            <br>
            <a
              href="https://stripe.com/docs/security/stripe"
              target="_blank"
              class="text-blue-700 hover:text-blue-600 hover:underline"
              @click="openUrl('https://stripe.com/docs/security/stripe')"
            >
              More technical information about how card details are stored
            </a>
          </div>
        </div>

        <div class="border border-b-0">
          <div
            class="flex items-center justify-between cursor-pointer p-3 pl-4 pr-4 bg-white hover:bg-gray-50"
            @click="openFAQ7 = !openFAQ7"
          >
            <div class="font-semibold brand-heading text-brand-500">
              How do I renew?
            </div>
            <div v-if="!openFAQ7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              /></svg>
            </div>
            <div v-if="openFAQ7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
              /></svg>
            </div>
          </div>

          <div
            v-if="openFAQ7"
            class="bg-gray-50 p-3 pl-4 pr-4 border-t border-gray-200"
          >
            <div class="">
              Renewing is easy. First you will need to check whether you have set up auto renew on your account when you joined.
              To check this click on <a
                v-if="environment !== 'cordova'"
                href="/Account"
                target="_blank"
                class="text-blue-700 hover:text-blue-600 hover:underline"
              >account</a>
              <router-link
                v-else
                to="/Account"
                class="text-blue-700 hover:text-blue-600 hover:underline"
              >
                account
              </router-link> from the main menu.
              <img
                :src="baseUrl + 'img/helpScreenshots/mainAccount.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            <div class="mt-6 mb-6">
              Navigate the the subscription tab and it will tell you if your account is set on Auto Renew.
              <img
                :src="baseUrl + 'img/helpScreenshots/autoRenew.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            If you are not set to auto renew you can renew through the <a
              v-if="environment !== 'cordova'"
              href="/Renew"
              target="_blank"
              class="text-blue-700 hover:text-blue-600 hover:underline"
            >renew</a>
            <router-link
              v-else
              to="/Renew"
              class="text-blue-700 hover:text-blue-600 hover:underline"
            >
              renew
            </router-link> tab on the home page.
            This enables you to renew at anytime during the year.
            You can also purchase any points you may require through the top up section.
            <br>
            <br>
            Please note when renewing early the subscription end date will extend by a year from your original expiry.
          </div>
        </div>

        <div class="border border-b-0">
          <div
            class="flex items-center justify-between cursor-pointer p-3 pl-4 pr-4 bg-white hover:bg-gray-50"
            @click="openFAQ8 = !openFAQ8"
          >
            <div class="font-semibold brand-heading text-brand-500">
              How do I buy more points?
            </div>
            <div v-if="!openFAQ8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              /></svg>
            </div>
            <div v-if="openFAQ8">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
              /></svg>
            </div>
          </div>

          <div
            v-if="openFAQ8"
            class="bg-gray-50 p-3 pl-4 pr-4 border-t border-gray-200"
          >
            <div class="">
              From the home page, navigate to the top up tab.
              <img
                :src="baseUrl + 'img/helpScreenshots/topUp.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            <div class="mt-6">
              Select the amount of points you'd like to top up by adding it to your basket.
              <img
                :src="baseUrl + 'img/helpScreenshots/pointsTopUp.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>

            <div class="mt-6">
              After adding a top up bundle to your basket, you should see it appear in your basket at the top right corner of your screen. If you can't see it you'll need to open it by clicking the basket icon. Click on checkout to pay for your points top up.
              <img
                :src="baseUrl + 'img/helpScreenshots/topUpBasket.png'"
                class="mt-2 mb-2 ml-auto mr-auto shadow-md border"
              >
            </div>
          </div>
        </div>

        <div class="border border-b-0">
          <div
            class="flex items-center justify-between cursor-pointer p-3 pl-4 pr-4 bg-white hover:bg-gray-50"
            @click="openFAQ9 = !openFAQ9"
          >
            <div class="font-semibold brand-heading text-brand-500">
              How do I cancel my renewal?
            </div>
            <div v-if="!openFAQ9">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-down"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
              /></svg>
            </div>
            <div v-if="openFAQ9">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="chevron-up"
                class="w-4 h-4 text-brand-500 hover:text-brand-300"
                role="img"
                viewBox="0 0 448 512"
              ><path
                fill="currentColor"
                d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
              /></svg>
            </div>
          </div>

          <div
            v-if="openFAQ9"
            class="bg-gray-50 p-3 pl-4 pr-4 border-t border-gray-200"
          >
            <div class="">
              To cancel your renewal please contact our Golf Team at {{ clubDetails.CustomerServiceEmail }}. If you are within the 14 day
              cooling off period, we will be able to refund your money straight away.
              The money normally takes between 3-5 working days to appear back in your account.
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="environment !== 'cordova'"
        class="mt-4"
      >
        <a
          href="https://form.asana.com/?k=lI-mNFCMqYpcU0FLrhC3dQ&d=1163201844111955"
          target="_blank"
        >
          <PrimaryButton
            :padding="'py-3'"
            :text="'Submit a bug report'"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { nodeEnv } from '@/environment/environment';
import services from '@/app/shared/services';
import PrimaryButton from '@/app/shared/components/base/button/primaryButton.vue';

export default {
  name: 'App',

  components: {
    PrimaryButton,
  },

  data() {
    return {
      clubId: 0,
      clubDetails: null,

      openFAQ1: false,
      openFAQ2: false,
      openFAQ3: false,
      openFAQ4: false,
      openFAQ5: false,
      openFAQ6: false,
      openFAQ7: false,
      openFAQ8: false,
      openFAQ9: false,

      baseUrl: process.env.VUE_APP_BASE_URL,
      environment: undefined,
    };
  },

  computed: {
    ...mapState({
      homeClubId: state => state.user.homeClubId,
      flexiPoints: state => state.user.flexiPoints,
    }),

    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
    }),
  },

  created() {
    this.clubId = this.homeClubId;

    if (this.clubId === 0) {
      this.clubId = this.$route.params.clubId;
    }

    this.$store.commit('setOverridingClubId', this.clubId);
  },

  mounted() {
    this.environment = nodeEnv;

    services.club.clubDetails(this.clubId).then((data) => {
      this.clubDetails = data;
    });
  },

  methods: {
    openUrl(url) {
      window.open(url);
    },

    getBackground() {
      return `background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.2)), url('${process.env.VUE_APP_BASE_URL}img/help.jpg'); background-size: cover; background-position: center;`;
    },
  },

};
</script>
