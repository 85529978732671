<template>
  <div

    class="brand-button-secondary transition-all transition-400 transition-ease-in-out select-none overflow-hidden leading-none align-baseline
    relative text-center appearance-none py-2 px-1"
    :class="getClasses()"
    @click="emitClick"
  >
    <div
      v-if="!loading"
      class="leading-none"
    >
      {{ text }}
    </div>
    <LoadingSpinner
      v-else
      :white="true"
      class=""
    />
  </div>
</template>

<script>
import LoadingSpinner from '../loadingSpinner.vue';

export default {

  components: {
    LoadingSpinner,
  },

  props: {
    text: {
      type: String,
      default: '',
    },
    textSize: {
      type: String,
      default: 'text-lg ',
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {

  },

  methods: {
    emitClick() {
      if (!this.loading) {
        this.$emit('click');
      }
    },

    getClasses() {
      let classes = `${this.textSize} `;

      if (this.fullWidth) {
        classes += 'w-full ';
      }

      if (this.loading) {
        classes += 'cursor-wait ';
      } else {
        classes += 'cursor-pointer ';
      }

      return classes;
    },
  },
};
</script>
