<template>
  <div>
    <transition name="fade">
      <div
        v-if="loading"
        class="h-screen flex justify-center items-center"
      >
        <LoadingSpinner />
      </div>
    </transition>

    <transition name="fade">
      <div v-if="!loading">
        <div class="block xs:hidden">
          <SubMenu
            :default-view="view"
            :sub-menu-items="subMenuItems"
            :item-notifications="subMenuItemNotifications"
            @click="showView"
          />
        </div>

        <div class="hidden xs:block">
          <img
            id="header-image"
            :src="currentTheme.coverImage3"
            class="header-image"
          >
        </div>

        <div class="xs:hidden block">
          <div
            class="h-64 mr-24 flex items-center justify-center bg-cover bg-center"
            :style="{ 'background-image': 'url(' + currentTheme.coverImage3 + ')' }"
          >
            <MemberBuddyCard class="-mr-16" />
          </div>
        </div>


        <div class="m-4 xs:m-6 brand-body">
          <MemberBuddyCard class="hidden xs:block" />

          <div class="pt-2 text-gray-800 ml-0 max-w-5xl ml-auto mr-auto hidden xs:block">
            <SubMenu
              :default-view="view"
              :sub-menu-items="subMenuItems"
              :item-notifications="subMenuItemNotifications"
              @click="showView"
            />
          </div>

          <div
            v-if="view === 'Requests'"
            class="mt-4 max-w-5xl ml-auto mr-auto border-gray-200"
            :class="buddyRequests.length > 0?'':'border-b pb-6 pt-4'"
          >
            <div v-if="buddyRequests.length > 0">
              <BuddyCard
                v-for="buddy in buddyRequests"
                :key="buddy.Target"
                :buddy="buddy"
                :type="1"
                @getBuddyRequests="getBuddyRequests"
                @getBuddies="getBuddies"
              />
            </div>

            <div
              v-else
              class="text-lg text-gray-400 text-center"
            >
              Empty
            </div>
          </div>

          <div
            v-if="view === 'Pending'"
            class="mt-4 max-w-5xl ml-auto mr-auto border-gray-200"
            :class="pendingBuddies.length > 0?'':'border-b pb-6'"
          >
            <div v-if="pendingBuddies.length > 0">
              <BuddyCard
                v-for="buddy in pendingBuddies"
                :key="buddy.Target"
                :buddy="buddy"
                :type="2"
                @getBuddyRequests="getBuddyRequests"
                @getBuddies="getBuddies"
              />
            </div>
            <div
              v-else
              class="text-lg text-gray-400 text-center pt-4"
            >
              Empty
            </div>
          </div>

          <div
            v-if="view === 'Buddies'"
            class="mt-4 max-w-5xl ml-auto mr-auto border-gray-200"
            :class="buddies.length > 0?'':'border-b pb-6'"
          >
            <div v-if="buddies.length > 0">
              <BuddyCard
                v-for="buddy in buddies"
                :key="buddy.publicId"
                :buddy="buddy"
                :type="0"
                @getBuddyRequests="getBuddyRequests"
                @getBuddies="getBuddies"
              />
            </div>
            <div
              v-else
              class="text-lg text-gray-400 text-center pt-4"
            >
              Empty
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div
      class="fixed bottom-0 right-0 text-2xl rounded-full px-3 py-2 shadow-md mb-8 mr-8 bg-brand-500 text-white cursor-pointer noSelect"
      @click="openFindBuddy = true"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
        data-prefix="fad"
        data-icon="user-plus"
        class="h-8 w-8 mt-1 mb-1"
        role="img"
        viewBox="0 0 640 512"
      ><g class="fa-group"><path
        class="fa-secondary"
        fill="currentColor"
        d="M640 224v32a16 16 0 0 1-16 16h-64v64a16 16 0 0 1-16 16h-32a16 16 0 0 1-16-16v-64h-64a16 16 0 0 1-16-16v-32a16 16 0 0 1 16-16h64v-64a16 16 0 0 1 16-16h32a16 16 0 0 1 16 16v64h64a16 16 0 0 1 16 16z"
        opacity="0.4"
      /><path
        class="fa-primary"
        fill="currentColor"
        d="M224 256A128 128 0 1 0 96 128a128 128 0 0 0 128 128zm89.6 32h-16.7a174.08 174.08 0 0 1-145.8 0h-16.7A134.43 134.43 0 0 0 0 422.4V464a48 48 0 0 0 48 48h352a48 48 0 0 0 48-48v-41.6A134.43 134.43 0 0 0 313.6 288z"
      /></g></svg>
    </div>

    <FindBuddy v-if="openFindBuddy" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from '../../shared/components/base/loadingSpinner.vue';
import BuddyCard from '../../shared/components/buddy/buddyCard.vue';
import MemberBuddyCard from '../../shared/components/buddy/memberBuddyCard.vue';
import FindBuddy from '../../shared/components/buddy/findBuddy.vue';
import SubMenu from '../../shared/components/base/menu/subMenu.vue';
import services from '@/app/shared/services';


export default {
  name: 'App',

  components: {
    LoadingSpinner,
    BuddyCard,
    FindBuddy,
    MemberBuddyCard,
    SubMenu,
  },

  data() {
    return {
      loading: true,
      view: 'Buddies',
      subMenuItems: ['Buddies', 'Requests', 'Pending'],
      buddies: undefined,
      pendingBuddies: undefined,
      buddyRequests: undefined,
      openFindBuddy: false,

      baseUrl: process.env.VUE_APP_BASE_URL,
    };
  },

  computed: {
    ...mapGetters({
      currentTheme: 'getCurrentTheme',
      club: 'getClub',
    }),

    subMenuItemNotifications() {
      if (this.buddyRequests !== undefined) {
        return [null, this.buddyRequests.length, null];
      }
      return undefined;
    },
  },

  mounted() {
    this.getBuddies();
    this.getBuddyRequests();
    this.getPendingBuddies();
  },

  methods: {
    showView(view) {
      this.view = view;
    },

    getBuddies() {
      services.buddy.buddies().then((result) => {
        this.buddies = result;
        this.resultComplete();
      });
    },

    getBuddyRequests() {
      services.buddy.buddyRequests().then((result) => {
        this.buddyRequests = result;
        this.resultComplete();
      });
    },

    getPendingBuddies() {
      services.buddy.pendingBuddies().then((result) => {
        this.pendingBuddies = result;
        this.resultComplete();
      });
    },

    resultComplete() {
      if (this.buddies !== undefined && this.pendingBuddies !== undefined && this.buddyRequests !== undefined) {
        this.loading = false;
      }
    },
  },

};
</script>
