import { Datetime } from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css';

import Vue from 'vue';
import VueOffline from 'vue-offline';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import router from './app/app-routes';
import App from './app/app.vue';
import { store } from '@/app/shared/stores/store';

if (process.env.NODE_ENV === 'production') {
  Analytics({
    app: 'XVIII The Grove',
    plugins: [
      googleTagManager({
        containerId: 'GTM-NXZPXJ',
      }),
    ],
  });
}

Vue.config.productionTip = false;

// if (process.env.NODE_ENV === 'production') {
//   Sentry.init({
//     dsn: 'https://99790a5220174711b56042d17197c0fd@o467145.ingest.sentry.io/5493058',
//     integrations: [
//       new VueIntegration({
//         Vue,
//         tracing: true,
//       }),
//       new Integrations.BrowserTracing(),
//     ],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//     sampleRate: 1.0,
//     captureUnhandledRejections: true,
//   });
// }

Vue.use(require('vue-moment'));

Vue.use(Datetime);

// To-do: no need to inject into every component
// https://www.npmjs.com/package/vue-offline
Vue.use(VueOffline);

const vue = new Vue({
  router,
  store,
  render: h => h(App),
});

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js').then((registration) => {
      console.log('SW registered: ', registration);
    }).catch((registrationError) => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}

vue.$mount('#app');
